import React from 'react';
import { useSelector } from 'react-redux';
import { sortedDBNotifications } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import DiscussionBoardRow from './DiscussionBoardRow';

function DiscussionBoardsTable() {
  const discussionBoardNotifications = useSelector((state) => sortedDBNotifications(state));

  return (
    <Table bordered hover className="bg-white mb-4 mt-4 sn-table align-cells-start">
      <thead>
        <tr>
          <th>{}</th>
          <th>Task</th>
          <th>Group</th>
          <th>Pod</th>
          <th>Starting</th>
          <th>Closing</th>
          <th>Extension</th>
          <th className="text-nowrap">Unread posts</th>
          <th>{}</th>
          <th>{}</th>
        </tr>
      </thead>
      <tbody>
        {discussionBoardNotifications?.map((db) => (
          <DiscussionBoardRow key={db.key} discussionBoard={db} />
        ))}
      </tbody>
    </Table>
  );
}

export default DiscussionBoardsTable;
