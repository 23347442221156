import React from 'react';

function PencilNotes() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3 1.5H14C14.7956 1.5 15.5587 1.81607 16.1213 2.37868C16.6839 2.94129 17 3.70435 17 4.5V19.5C17 20.2957 16.6839 21.0587 16.1213 21.6213C15.5587 22.1839 14.7956 22.5 14 22.5H3C2.20435 22.5 1.44129 22.1839 0.87868 21.6213C0.31607 21.0587 0 20.2957 0 19.5V4.5C0 3.70435 0.31607 2.94129 0.87868 2.37868C1.44129 1.81607 2.20435 1.5 3 1.5ZM3 3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V19.5C1.5 19.8978 1.65804 20.2794 1.93934 20.5607C2.22064 20.842 2.60218 21 3 21H14C14.3978 21 14.7794 20.842 15.0607 20.5607C15.342 20.2794 15.5 19.8978 15.5 19.5V4.5C15.5 4.10218 15.342 3.72064 15.0607 3.43934C14.7794 3.15804 14.3978 3 14 3H3Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4 16.2143C4 16.0059 4.06773 15.8061 4.18829 15.6587C4.30885 15.5114 4.47236 15.4286 4.64286 15.4286H8.5C8.6705 15.4286 8.83401 15.5114 8.95457 15.6587C9.07513 15.8061 9.14286 16.0059 9.14286 16.2143C9.14286 16.4227 9.07513 16.6225 8.95457 16.7699C8.83401 16.9172 8.6705 17 8.5 17H4.64286C4.47236 17 4.30885 16.9172 4.18829 16.7699C4.06773 16.6225 4 16.4227 4 16.2143ZM4 13.0714C4 12.863 4.06773 12.6632 4.18829 12.5158C4.30885 12.3685 4.47236 12.2857 4.64286 12.2857H12.3571C12.5276 12.2857 12.6912 12.3685 12.8117 12.5158C12.9323 12.6632 13 12.863 13 13.0714C13 13.2798 12.9323 13.4797 12.8117 13.627C12.6912 13.7744 12.5276 13.8571 12.3571 13.8571H4.64286C4.47236 13.8571 4.30885 13.7744 4.18829 13.627C4.06773 13.4797 4 13.2798 4 13.0714ZM4 9.92857C4 9.72019 4.06773 9.52034 4.18829 9.37299C4.30885 9.22564 4.47236 9.14286 4.64286 9.14286H12.3571C12.5276 9.14286 12.6912 9.22564 12.8117 9.37299C12.9323 9.52034 13 9.72019 13 9.92857C13 10.137 12.9323 10.3368 12.8117 10.4842C12.6912 10.6315 12.5276 10.7143 12.3571 10.7143H4.64286C4.47236 10.7143 4.30885 10.6315 4.18829 10.4842C4.06773 10.3368 4 10.137 4 9.92857ZM4 6.78571C4 6.57733 4.06773 6.37748 4.18829 6.23013C4.30885 6.08278 4.47236 6 4.64286 6H12.3571C12.5276 6 12.6912 6.08278 12.8117 6.23013C12.9323 6.37748 13 6.57733 13 6.78571C13 6.9941 12.9323 7.19395 12.8117 7.3413C12.6912 7.48865 12.5276 7.57143 12.3571 7.57143H4.64286C4.47236 7.57143 4.30885 7.48865 4.18829 7.3413C4.06773 7.19395 4 6.9941 4 6.78571Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5 4.5V3.5H21.5V4.5H19.5ZM19.5 6V17.8977C19.5 18.6272 19.7279 19.3384 20.1519 19.932L20.5 20.4193L20.8481 19.932C21.2721 19.3384 21.5 18.6272 21.5 17.8977V6H19.5ZM18 3C18 2.44772 18.4477 2 19 2H22C22.5523 2 23 2.44772 23 3V17.8977C23 18.9398 22.6744 19.9559 22.0687 20.8039L21.3137 21.8608C20.9149 22.4191 20.0851 22.4191 19.6863 21.8608L18.9313 20.8039C18.3256 19.9559 18 18.9398 18 17.8977V3Z" fill="currentColor" />
    </svg>
  );
}

export default PencilNotes;
