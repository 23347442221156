import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CommentPropType } from 'lib/propTypes';
import { CREATE_REPLY_COMMENT, CREATE_COMMENT } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import Textarea from 'components/shared/FormFields/Elements/Textarea';
import WordCount from './WordCount';
import './styles.scss';

function Reply({ comment, setShowReplyBox, isJournal, answerId }) {
  const dispatch = useDispatch();
  const { subGroupId, taskId } = useParams();
  const [reply, setComment] = useState('');
  const { fullName } = useSelector((state) => state.currentUser);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setShowReplyBox(false);
    const ACTION = isJournal ? CREATE_COMMENT : CREATE_REPLY_COMMENT;
    dispatch(ACTION.request({ newComment: reply, replyToCommentId: isJournal ? answerId : comment.id, subGroupId, taskId, isJournal: isJournal || false }));
  }, [answerId, comment.id, dispatch, isJournal, reply, setShowReplyBox, subGroupId, taskId]);

  return (
    <div className="reply-container">
      <div className="border-bottom p-2">
        {`Reply to ${comment.author} as ${fullName}`}
      </div>

      <form onSubmit={handleSubmit}>
        <Textarea
          name="postContent"
          value={reply}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Start typing here"
          className="form-control-md p-2 reply-textarea d-block"
        />
        <div className="d-flex align-items-center border-top p-2">
          <WordCount text={reply} />
          <Button type="submit" variant="primary" className="ms-auto">Reply</Button>
        </div>
      </form>
    </div>
  );
}

Reply.defaultProps = {
  isJournal: false,
  answerId: undefined,
};

Reply.propTypes = {
  comment: CommentPropType.isRequired,
  setShowReplyBox: PropTypes.func.isRequired,
  isJournal: PropTypes.bool,
  answerId: PropTypes.number,
};

export default Reply;
