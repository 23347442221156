import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CommentCard from './CommentCard';
import './styles.scss';

function Conversation({ commentId }) {
  const { replyIds } = useSelector((state) => state.facilitatorDashboard.commentsById[commentId]);
  const replies = replyIds.map((replyId) => <CommentCard key={replyId} commentId={replyId} isReply />);

  return (
    <>
      <CommentCard key={commentId} commentId={commentId} />
      {replies}
    </>
  );
}

Conversation.defaultProps = {
};

Conversation.propTypes = {
  commentId: PropTypes.number.isRequired,
};

export default Conversation;
