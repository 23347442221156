import React from 'react';

function ChevronLeft() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.031 2.46906C17.1008 2.53873 17.1563 2.62149 17.1941 2.71261C17.2319 2.80373 17.2513 2.90141 17.2513 3.00006C17.2513 3.09871 17.2319 3.19639 17.1941 3.28751C17.1563 3.37863 17.1008 3.46139 17.031 3.53106L8.5605 12.0001L17.031 20.4691C17.1718 20.6099 17.2509 20.8009 17.2509 21.0001C17.2509 21.1992 17.1718 21.3902 17.031 21.5311C16.8902 21.6719 16.6992 21.751 16.5 21.751C16.3008 21.751 16.1098 21.6719 15.969 21.5311L6.969 12.5311C6.89915 12.4614 6.84374 12.3786 6.80593 12.2875C6.76812 12.1964 6.74866 12.0987 6.74866 12.0001C6.74866 11.9014 6.76812 11.8037 6.80593 11.7126C6.84374 11.6215 6.89915 11.5387 6.969 11.4691L15.969 2.46906C16.0387 2.39921 16.1214 2.3438 16.2125 2.30599C16.3037 2.26818 16.4013 2.24872 16.5 2.24872C16.5986 2.24872 16.6963 2.26818 16.7874 2.30599C16.8786 2.3438 16.9613 2.39921 17.031 2.46906Z" fill="currentColor" />
    </svg>

  );
}

export default ChevronLeft;
