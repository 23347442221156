import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS, LOAD_INITIAL_STATE } from 'store/facilitatorDashboard/actions';
import { sortedJournalNotifications } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import JournalRow from './JournalRow';

function JournalsTable() {
  const currentUser = useSelector((state) => state.currentUser);
  const { loadingSubGroups } = useSelector((state) => state.facilitatorDashboard);
  const journalNotifications = useSelector((state) => sortedJournalNotifications(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!journalNotifications && currentUser?.id && !loadingSubGroups) {
      dispatch(LOAD_INITIAL_STATE.request());
      dispatch(LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS.request({ id: currentUser?.id }));
    }
  }, [currentUser?.id, dispatch, journalNotifications, loadingSubGroups]);

  return (
    <Table bordered hover className="bg-white mb-4 mt-4 sn-table align-cells-start">
      <thead>
        <tr>
          <th>{}</th>
          <th>Task</th>
          <th>Group</th>
          <th>Pod</th>
          <th>Starting</th>
          <th>Closing</th>
          <th>Extension</th>
          <th className="text-nowrap">Unread Posts</th>
          <th className="text-nowrap">Response %</th>
          <th>{}</th>
          <th>{}</th>
        </tr>
      </thead>
      <tbody>
        {journalNotifications?.map((journal) => (
          <JournalRow key={journal.key} journal={journal} />
        ))}
      </tbody>
    </Table>
  );
}

export default JournalsTable;
