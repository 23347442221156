import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import usePrevious from 'lib/hooks/usePrevious';
import { CoursePropType } from 'lib/propTypes';
import { LOAD_COURSE_GROUPS } from 'store/courses/actions';
import Table from 'react-bootstrap/Table';
import CourseTableSkeleton from './Skeleton';
import CourseRow from '../CourseRow';
import CourseGroupsModal from './CourseGroupsModal';

function CourseTable({ courses, loading, className }) {
  const dispatch = useDispatch();
  const [showGroupsCourseId, setShowGroupsCourseId] = useState();
  const [showGroups, setShowGroups] = useState(false);
  const prevShowGroupsCourseId = usePrevious(showGroupsCourseId);

  useEffect(() => {
    if (showGroupsCourseId && showGroupsCourseId !== prevShowGroupsCourseId) {
      dispatch(LOAD_COURSE_GROUPS.request(showGroupsCourseId));
      setShowGroups(true);
    }
  }, [showGroupsCourseId, prevShowGroupsCourseId, dispatch]);

  useEffect(() => {
    if (!showGroups) {
      setShowGroupsCourseId(null);
    }
  }, [showGroups]);

  return (
    <div className={`${className || ''}`}>

      {loading && courses.length === 0 && <CourseTableSkeleton />}

      {courses.length > 0 && (
      <Table bordered hover responsive className="sn-table bg-white">
        <thead>
          <tr>
            <th style={{ width: '35%' }}>Course Name</th>
            <th>Date created</th>
            <th>Number of groups</th>
            <th>Status</th>
            <th>Last updated</th>
            <th>{}</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => <CourseRow key={course?.id} course={course} setShowGroupsCourseId={setShowGroupsCourseId} />)}
        </tbody>
      </Table>
      )}

      <CourseGroupsModal courseId={showGroupsCourseId} showGroups={showGroups} setShowGroups={setShowGroups} />
    </div>
  );
}

CourseTable.defaultProps = {
  courses: [],
  loading: false,
  className: null,
};

CourseTable.propTypes = {
  courses: PropTypes.arrayOf(CoursePropType),
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default CourseTable;
