import React from 'react';
import LiveFeed from 'components/facilitator/LiveFeed/LiveFeed';

function GroupLiveFeedTabPage() {
  return (
    <LiveFeed />
  );
}

export default GroupLiveFeedTabPage;
