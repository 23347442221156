import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function JournalQuestion({ questionId }) {
  const question = useSelector((state) => state.facilitatorDashboard.questionsById[questionId]);

  return (
    <div className="card card-special mb-3 shadow-sm rounded py-3 px-4 fw-semibold text-accent-storm">
      {`${question.position}. ${question.body}`}
    </div>
  );
}

JournalQuestion.propTypes = {
  questionId: PropTypes.number.isRequired,
};

export default JournalQuestion;
