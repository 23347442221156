import React, { useMemo, useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchPut, fetchPost } from 'lib/apiHelpers';
import { HealthAuthorityPropType, OrganizationPropType, UserPropType } from 'lib/propTypes';
import { sentenceCase } from 'lib/utils';
import Yup from 'lib/validation';
import { DEFAULT_USER_PROPS } from 'pages/groups/GroupParticipantsBulkImport/constants';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import ConfirmLeave from './ConfirmLeave';
import ActiveUserItem from './ActiveUserItem';
import Row from './SummaryRow';
import './styles.scss';

const BulkImportReviewOne = (groupId, props) => fetchPut(`/api/registrar/groups/${groupId}/bulk_imports/review_one.json`, props);
const BulkImportSave = (groupId, props) => fetchPost(`/api/registrar/groups/${groupId}/bulk_imports/save.json`, props);

const ACTIVE_ERROR_CODES = ['active_in_other', 'already_in_group'];
const isValid = (user) => user.errors === undefined || Object.keys(user.errors).length === 0;

const FormSchema = Yup.object({
  user: Yup.object({
    firstName: Yup.string()
      .required('First name is missing')
      .test(
        'commas-quotes',
        'First name cannot include commas or double quotes',
        (value) => !value?.includes(',') && !value?.includes('"'),
      ),
    lastName: Yup.string()
      .required('Last name is missing')
      .test(
        'commas-quotes',
        'Last name cannot include commas or double quotes',
        (value) => !value?.includes(',') && !value?.includes('"'),
      ),
    phone: Yup.string(),
    email: Yup.string().isValidEmail().required('Invalid email')
      .test(
        async (value, context) => {
          if (!context.options) {
            return true;
          }

          const newUser = { ...context.options.user, email: context.originalValue };
          const result = await (BulkImportReviewOne(context.options.groupId, {
            participant: newUser,
            default_organization_id: context.options.organizationId,
            group_id: context.options.groupId,
          }));

          let newError = {};
          if (result.data[0].email) {
            newError = context.createError({ message: `Email ${result.data[0].email}`, path: 'user.email' });
            return newError;
          }
          if (newUser.userId !== result.data[0].userId) {
            newError = context.createError({ message: `Email ${result.data[0].email}`, params: { type: 'newUser', userId: result.data[0].userId }, path: 'user.email' });
            return newError;
          }
          return true;
        },
      )
      .test(
        'unique',
        'Email address duplicate in current import',
        (value, context) => (context.options.emails.includes(value)
          ? context.createError({ path: `${context.path}.email` })
          : true),
      ),
    address1: Yup.string(),
    address2: Yup.string(),
    city: Yup.string(),
    province: Yup.string(),
    postalCode: Yup.string(),
    languagePreference: Yup.string(),
    serviceOrganization: Yup.string(),
    department: Yup.string(),
    organization: Yup.string().required('Organization is missing'),
    healthAuthority: Yup.string().required('Health authority is missing'),
    ancestry: Yup.string(),
    jobCategory: Yup.string(),
    jobCategoryOther: Yup.string(),
    educationLevel: Yup.string(),
    ageGroup: Yup.string(),
    gender: Yup.string(),
    authorizerName: Yup.string()
      .test(
        'commas-quotes',
        'Authorizer name cannot include commas or double quotes',
        (value) => !value?.includes(',') && !value?.includes('"'),
      ),
    authorizerEmail: Yup.string().isValidEmail(),
    authorizerPhone: Yup.string(),
    employeeNumber: Yup.string(),
  }),
  organization: Yup.string(),
});

const updateLocalUsers = (localUsers, e, index, field, newErrors) => {
  if (e?.value === localUsers[index][field]) return localUsers;

  const newLocalUsers = [...localUsers];
  if (newErrors.params?.type === 'newUser') {
    newLocalUsers[index] = { ...newLocalUsers[index], [field]: e?.value, userId: newErrors.params.userId };
    delete newLocalUsers[index].errors[field];
  } else if (newErrors.message) {
    const spreadErrors = { ...newLocalUsers[index].errors, [field]: newErrors.message };
    newLocalUsers[index] = { ...newLocalUsers[index], [field]: e?.value, errors: spreadErrors };
  } else {
    newLocalUsers[index] = { ...newLocalUsers[index], [field]: e?.value };
    delete newLocalUsers[index].errors[field];
  }

  return newLocalUsers;
};

function Summary(props) {
  const { groupId, users, organizations, healthAuthorities, formData, onReset, handleProcessing } = props;
  const [usersWithNoErrorsCount, setUsersWithNoErrorsCount] = useState(0);
  const [selectedNewUsers, setSelectedNewUsers] = useState([]);
  const [selectedExistingUsers, setSelectedExistingUsers] = useState([]);
  const [localUsers, setLocalUsers] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [saveError, setSaveError] = useState();
  const [showRestartModal, setShowRestartModal] = useState(false);

  const handleRestartModalClose = () => setShowRestartModal(false);
  const handleRestart = () => {
    onReset();
  };

  const activeUsers = useMemo(
    () => users.users
      .filter((user) => ACTIVE_ERROR_CODES.includes(user.errorCode))
      .map((user) => <ActiveUserItem key={user.key} user={user} />),
    [users.users],
  );

  const tableContainerRef = useRef();
  const formRef = useRef();
  const selectAllRef = useRef();
  const rowRef = useRef();
  const buttonContainerRef = useRef();
  const formDataOrganizations = useMemo(() => organizations?.map((org) => ({ label: org.shortName, value: org.shortName })), [organizations]);
  const formDataHealthAuthorities = useMemo(() => healthAuthorities?.map((ha) => ({ label: ha.name, value: ha.name, org: ha.organizationName })), [healthAuthorities]);

  useEffect(() => {
    setLocalUsers(users.users);
    setSelectedNewUsers(users.users.filter((user) => !user.userId && !isValid(user)).map((user) => user.key));
  }, [users.users]);

  useLayoutEffect(() => {
    if (tableContainerRef.current) {
      // 100vh - header - buttonContainer - react-root top (don't have to consider env-banner or navbar this way)
      tableContainerRef.current.style.height = `calc(100vh - 75px - ${buttonContainerRef.current.offsetHeight}px - ${document.getElementById('react-root').offsetTop}px`;
    }
  }, []);

  useLayoutEffect(() => {
    let newWidth = -24; // 24px is the initial position of the sticky column
    setTimeout(() => { // wait for the table to render
      rowRef.current.querySelectorAll('.bi-sticky-col').forEach((el, i, arr) => {
        if (i > 0) {
          newWidth += arr[i - 1].offsetWidth;
          // eslint-disable-next-line no-param-reassign
          el.style.left = `${newWidth}px`;
        }
      });
    }, 300);
  }, []);

  useEffect(() => {
    if (selectAllRef.current) {
      const selectedUsersCount = selectedExistingUsers.length + selectedNewUsers.length;
      selectAllRef.current.checked = selectedUsersCount === usersWithNoErrorsCount;
      selectAllRef.current.indeterminate = (selectedUsersCount > 0 && selectedUsersCount < usersWithNoErrorsCount);
    }
  }, [usersWithNoErrorsCount, selectedExistingUsers.length, selectedNewUsers.length]);

  const handleSelectAll = (e) => {
    const checkAll = e.target.checked;
    const checkboxes = document.querySelectorAll('input[id^=include-in-import]:not(:disabled)');
    const checkboxesExisting = [];
    const checkboxesNew = [];
    checkboxes.forEach((el) => {
      el.checked = checkAll; // eslint-disable-line no-param-reassign
      if (checkAll) {
        if (el.name.includes('Existing')) {
          checkboxesExisting.push(el.value);
        } else {
          checkboxesNew.push(el.value);
        }
      }
    });

    setSelectedExistingUsers(checkboxesExisting);
    setSelectedNewUsers(checkboxesNew);
  };

  const handleSelectRow = useCallback((e) => {
    const { checked, value, name } = e.target;
    const updater = name.includes('Existing') ? setSelectedExistingUsers : setSelectedNewUsers;
    updater((prev) => (checked ? prev.concat(value) : prev.filter((user) => user !== value)));
  }, []);

  const handleFieldBlurV2 = useCallback(async (e, index, field) => {
    let validationOptions = {};
    if (field === 'email') {
      const formValues = new FormData(formRef.current);
      const formEntries = Object.fromEntries(formValues);
      const formArray = Object.entries(formEntries);
      validationOptions = formArray.reduce((acc, [k, v]) => {
        if (k.includes(`user.${index}.`)) {
          acc.user[k.replace(`user.${index}.`, '')] = v;
        }
        if (k.includes('.email') && !k.includes(`user.${index}.email`)) {
          acc.emails.push(v);
        }
        return acc;
      }, { user: {}, emails: [] });
    }
    let newErrors = {};
    await Yup.reach(FormSchema, `user.${field}`)
      .validate(e?.value, { groupId, organizationId: users.organizationId, ...validationOptions })
      .catch((val) => { newErrors = val; });

    setLocalUsers((prev) => updateLocalUsers(prev, e, index, field, newErrors));
  }, [groupId, users.organizationId]);

  // create list of submittable users
  const memoRows = useMemo(() => {
    const usersErrors = [];
    const usersNoErrors = [];
    const existingUsers = [];
    const existingUserKeys = [];
    const newUserKeys = [];

    if (localUsers) {
      localUsers.forEach((user, index) => {
        if (ACTIVE_ERROR_CODES.includes(user.errorCode)) return;
        if (user.userId && isValid(user)) {
          existingUserKeys.push(user.key);
          existingUsers.push(
            <Row
              key={user.key}
              u={user}
              index={index}
              handleFieldBlur={handleFieldBlurV2}
              handleSelectRow={handleSelectRow}
              formData={formData}
              formDataOrganizations={formDataOrganizations}
              formDataHealthAuthorities={formDataHealthAuthorities}
            />,
          );
        } else if (isValid(user)) {
          newUserKeys.push(user.key);
          usersNoErrors.push(
            <Row
              key={user.key}
              u={user}
              index={index}
              handleFieldBlur={handleFieldBlurV2}
              handleSelectRow={handleSelectRow}
              formData={formData}
              formDataOrganizations={formDataOrganizations}
              formDataHealthAuthorities={formDataHealthAuthorities}
            />,
          );
        } else {
          usersErrors.push(
            <Row
              key={user.key}
              u={user}
              index={index}
              handleFieldBlur={handleFieldBlurV2}
              formData={formData}
              formDataOrganizations={formDataOrganizations}
              formDataHealthAuthorities={formDataHealthAuthorities}
            />,
          );
        }
      });
    }

    // Ensure that the selected users are still in the list
    setSelectedExistingUsers((prev) => prev.filter((user) => existingUserKeys.includes(user)));
    setSelectedNewUsers(newUserKeys);
    setUsersWithNoErrorsCount(existingUsers.length + usersNoErrors.length);

    return (
      <>
        {existingUsers.length > 0 && (
          <tbody>
            <tr className="fw-semibold pb-0 mt-4 mb-0">
              <th className="bi-sticky-col overlap py-2" scope="rowgroup" colSpan={5}>
                {`${existingUsers.length} Participant${existingUsers.length === 1 ? '' : 's'} that already exist${existingUsers.length === 1 ? 's' : ''}`}
              </th>
            </tr>
            {existingUsers}
          </tbody>
        )}

        {usersErrors.length > 0 && (
          <tbody>
            <tr className="fw-semibold pb-0 mt-4 mb-0">
              <th className="bi-sticky-col overlap py-2" scope="rowgroup" colSpan={5}>
                {`${usersErrors.length} Participant${usersErrors.length === 1 ? '' : 's'} with errors`}
              </th>
            </tr>
            {usersErrors}
          </tbody>
        )}

        {usersNoErrors.length > 0 && (
          <tbody>
            <tr className="fw-semibold pb-0 mt-4 mb-0">
              <th className="bi-sticky-col overlap py-2" scope="rowgroup" colSpan={5}>
                {`${usersNoErrors.length} New participant${usersNoErrors.length === 1 ? '' : 's'}`}
              </th>
            </tr>
            {usersNoErrors}
          </tbody>
        )}
      </>
    );
  }, [formData, formDataOrganizations, formDataHealthAuthorities, handleFieldBlurV2, handleSelectRow, localUsers]);

  const bulkImportSubmit = useCallback(() => {
    setSubmitting(true);

    BulkImportSave(groupId, {
      participants: localUsers.filter((user) => selectedNewUsers.concat(selectedExistingUsers).includes(user.key)),
      default_organization_id: users.organizationId,
      group_id: groupId,
    }).then((result) => {
      if (result.ok) {
        handleProcessing();
      }
    }).catch((e) => {
      setSaveError({
        error: true,
        message: (e.details && e.details.message) || 'There was an error while saving.',
      });
      setSubmitting(false);
    });
  }, [localUsers, selectedExistingUsers, selectedNewUsers, groupId, users.organizationId, setSubmitting, handleProcessing]);

  return (
    <>
      <ConfirmLeave />

      {saveError && (
        <Alert variant="danger">
          {saveError.message}
        </Alert>
      )}

      <div ref={tableContainerRef} className="bi-table-container p-4" style={{ height: '75vh' }}>
        {activeUsers.length > 0 && (
          <div className="bi-sticky-col px-1">
            <p className="fw-semibold mb-1 text-small">
              {`${activeUsers.length} Participant${activeUsers.length === 1 ? '' : 's'} already in an active group`}
            </p>
            <ul className="list-unstyled ps-3 text-small">
              {activeUsers}
            </ul>
          </div>
        )}

        <Form
          ref={formRef}
          autoComplete="false"
          data-1p-ignore // ignore 1Password
          data-lpignore
        >
          <table className="bi-summary-table table table-sm mb-0">
            <thead>
              <tr ref={rowRef} className="sticky-top" style={{ top: -24 }}>
                <th className="bi-sticky-col">{}</th>
                <th className="bi-sticky-col align-middle" style={{ minWidth: '2rem' }}>
                  <div className="form-check" style={{ minHeight: 0 }}>
                    <input ref={selectAllRef} className="form-check-input" type="checkbox" defaultChecked={false} name="selectAll" id="selectAll" onClick={handleSelectAll} />
                    <label className="visually-hidden" htmlFor="selectAll">
                      Include all in import
                    </label>
                  </div>
                </th>
                {Object.keys(DEFAULT_USER_PROPS).map((prop, i) => (
                  <th key={prop} className={`${i < 3 ? 'bi-sticky-col' : ''}${i === 2 ? ' overlap' : ''}`}>
                    {sentenceCase(prop)}
                  </th>
                ))}
              </tr>
            </thead>
            {memoRows}
          </table>
        </Form>
      </div>

      <div ref={buttonContainerRef} className="d-flex align-items-center py-2 px-4">
        <Button variant="primary" onClick={() => setShowRestartModal(true)} className="flush btn-plain">
          Back
        </Button>

        <div className="ms-auto">
          <Button disabled={usersWithNoErrorsCount === 0 || isSubmitting} onClick={bulkImportSubmit} variant="success" type="submit">
            {`Import ${(selectedExistingUsers.length + selectedNewUsers.length)} participants of ${users.users.length}`}
            {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
          </Button>
        </div>
      </div>

      <DefaultModal
        isOpen={showRestartModal}
        onClose={handleRestartModalClose}
        header="You have unsaved changes"
        footerComponent={(
          <>
            <Button variant="outline-secondary" onClick={handleRestartModalClose}>No</Button>
            <Button
              variant="outline-danger"
              onClick={handleRestart}
            >
              Yes
            </Button>
          </>
            )}
      >
        Are you sure you want to reset the import form?
      </DefaultModal>
    </>
  );
}

Summary.defaultProps = {
  formData: {},
  organizations: [],
  healthAuthorities: [],
  onReset: () => {},
  handleProcessing: () => {},
};

Summary.propTypes = {
  groupId: PropTypes.number.isRequired,
  users: PropTypes.shape({
    users: PropTypes.arrayOf(UserPropType),
    organizationId: PropTypes.number,
  }).isRequired,
  organizations: PropTypes.arrayOf(OrganizationPropType),
  healthAuthorities: PropTypes.arrayOf(HealthAuthorityPropType),
  onReset: PropTypes.func,
  formData: PropTypes.shape({
    gender: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    ageGroup: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    educationLevel: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    jobCategory: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    ancestry: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
  }),
  handleProcessing: PropTypes.func,
};

export default Summary;
