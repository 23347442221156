import * as yup from 'yup';

// Rails URI::MailTo::EMAIL_REGEXP
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isValidEmail(message = 'Invalid email') {
  return this.matches(EMAIL_REGEX, { message, excludeEmptyString: true });
}

yup.addMethod(yup.string, 'isValidEmail', isValidEmail);

export default yup;
