import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CREATE_COMMENT } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import Textarea from 'components/shared/FormFields/Elements/Textarea';
import WordCount from './WordCount';
import './styles.scss';

function Comment({ taskId, subGroupId, isJournal, answerId }) {
  const dispatch = useDispatch();
  const [newComment, setComment] = useState('');
  const currentUser = useSelector((state) => state.currentUser);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setComment('');
    dispatch(CREATE_COMMENT.request({ newComment, subGroupId, taskId, isJournal, replyToCommentId: answerId }));
  }, [answerId, dispatch, isJournal, newComment, subGroupId, taskId]);

  return (
    <div className="reply-container col-md-12">
      <div className="border-bottom p-2">
        {`Post comment as ${currentUser?.fullName}`}
      </div>

      <form onSubmit={handleSubmit}>
        <Textarea
          name="postContent"
          onChange={(e) => setComment(e.target.value)}
          value={newComment}
          placeholder="Start typing here"
          className="form-control-md p-2 reply-textarea"
        />

        <div className="d-flex align-items-center border-top p-2">
          <WordCount text={newComment} />
          <Button type="submit" variant="primary" className="ms-auto">Comment</Button>
        </div>
      </form>
    </div>
  );
}
Comment.defaultProps = {
  isJournal: false,
  answerId: null,
};

Comment.propTypes = {
  taskId: PropTypes.string.isRequired,
  subGroupId: PropTypes.string.isRequired,
  isJournal: PropTypes.bool,
  answerId: PropTypes.number,
};

export default Comment;
