import React from 'react';

function Book() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.821 1.60798C7.2195 1.12799 10.374 1.06498 12.531 3.21898C12.6006 3.28876 12.6558 3.37158 12.6933 3.46269C12.7309 3.5538 12.7502 3.65143 12.75 3.74998V20.25C12.7503 20.3985 12.7064 20.5438 12.624 20.6674C12.5416 20.7909 12.4244 20.8873 12.2872 20.9442C12.15 21.001 11.999 21.0159 11.8533 20.9868C11.7077 20.9578 11.5739 20.8861 11.469 20.781C10.2045 19.515 8.2965 19.1925 6.264 19.401C4.248 19.611 2.274 20.3265 1.086 20.9205C0.971656 20.9778 0.84456 21.0049 0.716789 20.9992C0.589019 20.9936 0.464819 20.9553 0.355993 20.8881C0.247167 20.8209 0.157331 20.727 0.0950232 20.6153C0.0327149 20.5037 4.2917e-06 20.3779 0 20.25L0 3.74998C4.67001e-06 3.61082 0.0387305 3.4744 0.111845 3.35598C0.18496 3.23757 0.289579 3.14183 0.414 3.07948L0.75 3.74998L0.414 3.07948L0.417 3.07799L0.423 3.07498L0.4425 3.06598C0.551237 3.01183 0.661269 2.96031 0.7725 2.91148C2.07074 2.3301 3.42887 1.89298 4.8225 1.60798H4.821ZM1.5 4.22998V19.092C2.769 18.5775 4.416 18.084 6.111 17.91C7.8255 17.733 9.6915 17.8755 11.25 18.75V4.07698C9.6555 2.68348 7.3035 2.64298 5.115 3.08098C3.87278 3.33514 2.661 3.72029 1.5 4.22998Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.179 1.60798C16.782 1.12799 13.626 1.06498 11.469 3.21898C11.3994 3.28876 11.3442 3.37158 11.3067 3.46269C11.2691 3.5538 11.2498 3.65143 11.25 3.74998V20.25C11.2497 20.3985 11.2936 20.5438 11.376 20.6674C11.4584 20.7909 11.5756 20.8873 11.7128 20.9442C11.85 21.001 12.001 21.0159 12.1467 20.9868C12.2923 20.9578 12.4261 20.8861 12.531 20.781C13.7955 19.515 15.7035 19.1925 17.736 19.401C19.752 19.611 21.726 20.3265 22.914 20.9205C23.0283 20.9778 23.1554 21.0049 23.2832 20.9992C23.411 20.9936 23.5352 20.9553 23.644 20.8881C23.7528 20.8209 23.8427 20.727 23.905 20.6153C23.9673 20.5037 24 20.3779 24 20.25V3.74998C24 3.61082 23.9613 3.4744 23.8882 3.35598C23.815 3.23757 23.7104 3.14183 23.586 3.07948L23.25 3.74998L23.586 3.07948L23.583 3.07799L23.577 3.07498L23.5575 3.06598L23.487 3.03148C23.0935 2.84775 22.6937 2.67764 22.2885 2.52148C21.2792 2.13097 20.2391 1.8254 19.179 1.60798ZM22.5 4.22998V19.092C21.231 18.5775 19.584 18.084 17.889 17.91C16.1745 17.733 14.3085 17.8755 12.75 18.75V4.07698C14.3445 2.68348 16.6965 2.64298 18.885 3.08098C20.1272 3.33512 21.339 3.72027 22.5 4.22998Z" fill="currentColor" />
    </svg>
  );
}

export default Book;
