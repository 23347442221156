import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { MARK_CONFLICT } from 'store/indigenousWelcomeLetters/actions';
import Button from 'react-bootstrap/Button';

function AssignmentListItem({ itemId, selectParticipant }) {
  const { id: assignmentId, participant } = useSelector((state) => state.indigenousWelcomeLetters.assignments.byId[itemId]);
  const dispatch = useDispatch();

  const handleCancelClick = () => {
    selectParticipant({ participantName: participant.name, assignmentId });
  };

  const handleConflictClick = () => {
    dispatch(MARK_CONFLICT.request({ assignmentId }));
  };

  return (
    <tr>
      <td>
        <Link className="btn-plain" to={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: participant.participationId })}>
          {participant.name}
        </Link>
      </td>
      <td>
        {participant.groupName}
        <br />
        {participant.subGroupName}
      </td>
      <td>{`${participant.percentageDoneCourse}%`}</td>
      <td>
        {participant.jobCategory}
        <br />
        {participant.city}
      </td>
      <td>{participant.healthAuthority}</td>
      <td>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={handleCancelClick}
        >
          <i className="fas fa-ban me-1" />
          Cancel
        </Button>
      </td>
      <td>
        <Button
          variant="secondary"
          size="sm"
          onClick={handleConflictClick}
        >
          <i className="fas fa-user-alt-slash me-1" />
          Conflict
        </Button>
      </td>
    </tr>
  );
}

AssignmentListItem.defaultProps = {
  selectParticipant: () => {},
};

AssignmentListItem.propTypes = {
  itemId: PropTypes.number.isRequired,
  selectParticipant: PropTypes.func,
};

export default AssignmentListItem;
