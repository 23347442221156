import { takeLatest, put, select, call } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { normalizeArray } from 'lib/reducerHelpers';
import { CHANGE_CURRENT_FACILITATOR, GET_DASHBOARD_FOR_FACILITATOR, GET_SECONDARY_DASHBOARD_DATA, LOAD_INITIAL_STATE, LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS } from 'store/facilitatorDashboard/actions';
import { GET_CURRENT_USER_NOTIFICATIONS } from 'store/actions';
import { waitFor } from 'sagas/sagas';

export default function* sagas() {
  yield takeLatest(CHANGE_CURRENT_FACILITATOR.SYNC, function* getDashboardForFacilitator() {
    yield call(waitFor, (state) => state.currentUser); // ensure currentUser is loaded
    const state = yield select();
    const userId = state.facilitatorDashboard.currentFacilitator.id;

    if (userId !== state.currentUser.id) {
      yield put(GET_CURRENT_USER_NOTIFICATIONS.request());
    }

    try {
      const { data } = yield fetchGet(
        '/api/facilitator/dashboard/notifications',
        { userId },
      );

      if (Object.keys(data.notifications).length === 0) {
        yield put(GET_DASHBOARD_FOR_FACILITATOR.success({}));
        return;
      }

      yield put(GET_DASHBOARD_FOR_FACILITATOR.success(data.notifications));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_DASHBOARD_FOR_FACILITATOR);
    }
  });

  yield takeLatest(LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS.REQUEST, function* loadFacilitatorDiscussionBoards({ payload }) {
    yield call(waitFor, (state) => state.currentUser); // ensure currentUser is loaded
    const { currentUser } = yield select();
    const userId = payload?.id || currentUser.id;

    try {
      const { data } = yield fetchGet(
        '/api/facilitator/dashboard/notifications',
        { userId },
      );

      yield put(LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS.success({ userId, data }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_FACILITATOR_DASHBOARD_NOTIFICATIONS);
    }
  });

  yield takeLatest(GET_DASHBOARD_FOR_FACILITATOR.SUCCESS, function* getSecondaryDashboardData() {
    yield put(GET_SECONDARY_DASHBOARD_DATA.request());

    const { facilitatorDashboard: { subGroups } } = yield select();
    const subGroupIds = subGroups ? Object.keys(subGroups).join(',') : [];
    if (subGroupIds.length === 0) {
      yield put(GET_SECONDARY_DASHBOARD_DATA.success());
      return;
    }
    try {
      const insufficientParticipations = yield fetchGet(
        '/api/facilitator/dashboard/insufficient_participations',
        { subGroupIds },
      );

      const flaggedJournals = yield fetchGet(
        '/api/facilitator/dashboard/flagged_journals',
        { subGroupIds },
      );

      const extendedParticipants = yield fetchGet(
        '/api/facilitator/dashboard/extended_participants',
        { subGroupIds },
      );

      yield put(GET_SECONDARY_DASHBOARD_DATA.success({ insufficientParticipations, flaggedJournals, extendedParticipants }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_SECONDARY_DASHBOARD_DATA);
    }
  });

  yield takeLatest(LOAD_INITIAL_STATE.REQUEST, function* loadInitialState() {
    yield call(waitFor, (state) => state.currentUser); // ensure currentUser is loaded
    const { currentUser } = yield select();
    // Get all facilitators for the dropDown
    const { data } = yield fetchGet('/api/facilitator/facilitators');

    yield put(LOAD_INITIAL_STATE.success({ facilitators: normalizeArray(data) }));
    if (currentUser.isFacilitator || currentUser.isSuperFacilitator) {
      yield put(CHANGE_CURRENT_FACILITATOR.action({ id: currentUser.id }));
    } else {
      // Load the first facilitator if the current user is a registrar
      // or San'yas Sysadmin
      yield put(CHANGE_CURRENT_FACILITATOR.action({ id: data[0].id }));
    }
  });
}
