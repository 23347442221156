import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FlaggedJournalPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import Spinner from 'components/shared/Spinner';

function FlaggedJournalList({ groupId, subGroupId, journals }) {
  if (!journals) {
    return <div className="col-md-4 text-center"><Spinner /></div>;
  }
  if (journals.length === 0) {
    return <div className="col-md-4" />;
  }
  journals.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
  return (
    <div className="col-md-4">
      <h5 style={{ marginTop: 0 }}>Flagged Journals</h5>
      <ul>
        {
          journals.map((journal) => (
            <li key={journal.groupMembershipId} className="my-1">
              <span className="label priority3">

                <Link
                  to={buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id: groupId, subGroupId, taskId: journal.taskId, groupMembershipId: journal.groupMembershipId })}
                  className="btn-plain px-1"
                >
                  {journal.fullName}
                </Link>
              </span>
            </li>
          ))
        }
      </ul>
    </div>

  );
}

FlaggedJournalList.defaultProps = {
  journals: [],
};

FlaggedJournalList.propTypes = {
  groupId: PropTypes.number.isRequired,
  subGroupId: PropTypes.number.isRequired,
  journals: PropTypes.arrayOf(FlaggedJournalPropType),
};

export default FlaggedJournalList;
