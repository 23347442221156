import React from 'react';

function Star() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7186_30576)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.29906 22.275C4.18205 22.941 4.83906 23.4615 5.41806 23.1645L12.0031 19.7805L18.5866 23.1645C19.1656 23.4615 19.8226 22.941 19.7056 22.2765L18.4606 15.1815L23.7451 10.1475C24.2386 9.67651 23.9821 8.81551 23.3206 8.72251L15.9736 7.67851L12.6976 1.18801C12.6351 1.05644 12.5367 0.945281 12.4136 0.867445C12.2906 0.789609 12.1479 0.748291 12.0023 0.748291C11.8567 0.748291 11.7141 0.789609 11.591 0.867445C11.4679 0.945281 11.3695 1.05644 11.3071 1.18801L8.03106 7.68001L0.684055 8.72401C0.0225553 8.81701 -0.233945 9.67801 0.259555 10.149L5.54406 15.183L4.29906 22.278V22.275ZM11.6566 18.1245L6.12756 20.9655L7.16856 15.03C7.19294 14.8934 7.1834 14.7528 7.14077 14.6208C7.09814 14.4887 7.02372 14.3691 6.92406 14.2725L2.56506 10.1175L8.64305 9.25351C8.76891 9.2345 8.88829 9.18527 8.99095 9.11003C9.09362 9.0348 9.17652 8.93579 9.23256 8.82151L12.0031 3.33451L14.7721 8.82151C14.8281 8.93579 14.911 9.0348 15.0137 9.11003C15.1163 9.18527 15.2357 9.2345 15.3616 9.25351L21.4396 10.116L17.0806 14.271C16.9805 14.3677 16.9059 14.4875 16.8633 14.6199C16.8206 14.7523 16.8113 14.8931 16.8361 15.03L17.8771 20.9655L12.3481 18.1245C12.2411 18.0694 12.1226 18.0406 12.0023 18.0406C11.882 18.0406 11.7635 18.0694 11.6566 18.1245Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_7186_30576">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Star;
