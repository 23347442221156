import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_INITIAL_STATE } from 'store/facilitatorDashboard/actions';
import Table from 'react-bootstrap/Table';
import AnswerRow from './Journals/AnswerRow';

function PreviewJournalAnswersTable() {
  const currentUser = useSelector((state) => state.currentUser);
  const { subGroupId, taskId } = useParams();
  const { journalPreviewsById } = useSelector((state) => state.facilitatorDashboard);
  const subGroup = useSelector((state) => state.facilitatorDashboard.subGroups && state.facilitatorDashboard.subGroups[subGroupId]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!subGroup?.journalNotifications && currentUser?.id) {
      dispatch(LOAD_INITIAL_STATE.request());
    }
  }, [currentUser?.id, dispatch, subGroup?.journalNotifications]);

  if (!subGroup?.journalNotifications) return null;

  return (
    <Table bordered hover className="bg-white mb-4 mt-4 sn-table align-cells-start table table-bordered table-hover">
      <thead>
        <tr>
          <th>{}</th>
          <th>Task</th>
          <th>Participant</th>
          <th>Date Posted</th>
          <th>In session %</th>
          <th>{}</th>
        </tr>
      </thead>
      <tbody>
        {journalPreviewsById && Object.values(journalPreviewsById).map((answer) => (
          <AnswerRow
            key={answer.participationId}
            internalName={subGroup.journalNotifications[taskId]?.internalName}
            answer={answer}
            journalTaskId={taskId}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default PreviewJournalAnswersTable;
