import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_GROUP_SHOW_PATH, FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';

function ConversationParticipant({ fromUser, toUser }) {
  const participant = fromUser.isFacilitator ? toUser : fromUser;
  return (
    <div>
      <div className="row row-space-top">
        <div className="col-md-12">
          {!participant.groupMembershipId && (
            <p className="well">There is either no participant in this conversation, or the participant is not registered in a group</p>
          )}
          {
            participant.groupMembershipId && (
            <p className="well">
              Conversation with
              {' '}
              <Link to={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: participant.groupMembershipId })}>
                {`${participant.firstName} ${participant.lastName}`}
              </Link>
              {' '}
              in group
              {' '}
              <Link to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id: participant.groupId })}>{participant.groupName}</Link>
              .
            </p>
            )
          }
        </div>
      </div>
    </div>
  );
}

ConversationParticipant.propTypes = PropTypes.shape({
  fromUser: UserPropType,
  toUser: UserPropType,
}).isRequired;

export default ConversationParticipant;
