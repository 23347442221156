import React from 'react';
import PropTypes from 'prop-types';
import { JournalDataPropType } from 'lib/propTypes';
import NotificationDot from 'components/shared/NotificationDot';

function JournalDot({ data, primary, className }) {
  const count = primary ? data?.primaryJournalNotificationCount : data?.secondaryJournalNotificationCount;
  const ageLabel = primary ? data?.primaryAgeLabel : data?.secondaryAgeLabel;

  if (!data || count < 1) return null;

  return (
    <NotificationDot age={ageLabel} primary={primary} className={className} />
  );
}

JournalDot.defaultProps = {
  data: undefined,
  primary: false,
  className: '',
};

JournalDot.propTypes = {
  data: JournalDataPropType,
  primary: PropTypes.bool,
  className: PropTypes.string,
};

export default JournalDot;
