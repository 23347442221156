import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnswerPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD } from 'store/facilitatorDashboard/actions';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import MultilineText from 'components/shared/MultilineText';
import NotificationDot from '../NotificationDot';
import Reply from './Reply';
import './styles.scss';

function JournalAnswerCard({ answer }) {
  const { subGroupId } = useParams();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [toggleBox, setToggleBox] = useState(answer.notificationCleared);
  const { assignedFacilitatorId } = useSelector((state) => state.facilitatorDashboard);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const markComment = useCallback((isChecked) => {
    setToggleBox(isChecked);
    if (isChecked) {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.ANSWER_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.ANSWER_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    }
    document.activeElement.blur();
  }, [answer, dispatch, subGroupId]);

  return (
    <div className="mb-2">
      <div className="card bg-white shadow-sm rounded pt-3 px-4">
        <div className="d-flex justify-content-between position-relative">
          {!answer.notificationCleared && (
            <div className="position-absolute top-50 start-0 translate-middle" style={{ paddingRight: '1.375rem' }}>
              <NotificationDot age={answer.notificationPriority} style={{ marginBottom: 2 }} />
            </div>
          )}

          <div className="d-flex align-items-baseline">
            <SanyasIcon name="profileInfo" className="me-1 text-accent-land" />
            <a
              href={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: answer.participationId })}
              className="btn-plain fw-semibold"
              target="_blank"
              rel="noreferrer"
            >
              {answer.fullName}
            </a>
          </div>

          {isAssignedFacilitationPoint && (
            <div>
              <label className="btn-plain align-self-center read-label btn-primary me-2" htmlFor={answer.answerId}>
                <input
                  onChange={() => markComment(!answer.notificationCleared)}
                  checked={toggleBox}
                  disabled={!isAssignedFacilitationPoint}
                  id={answer.answerId}
                  name={answer.answerId}
                  type="checkbox"
                  className="form-check-input me-2"
                />
                {answer.notificationCleared ? 'Read' : 'Unread'}
              </label>
            </div>
          )}

        </div>

        <MultilineText text={answer.body} />

        <div className="d-flex justify-content-end mb-1">
          <Button disabled={!isAssignedFacilitationPoint} onClick={() => setShowReplyBox(!showReplyBox)} variant="plain" className="me-1 px-1">
            <SanyasIcon name="chat" className="me-1" />
            Reply
          </Button>
        </div>
      </div>

      <div className="col-10 offset-2">
        {showReplyBox && <Reply setShowReplyBox={setShowReplyBox} comment={answer} isJournal />}
      </div>
    </div>
  );
}

JournalAnswerCard.defaultProps = {
  notificationId: undefined,
};

JournalAnswerCard.propTypes = {
  answer: AnswerPropType.isRequired,
  notificationId: PropTypes.number,
};

export default JournalAnswerCard;
