import { COUNTRIES } from './countries';

const DEFAULT_TIMEOUT = 5000;

const SIZES = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

const BUTTON_SIZE_CLASSES = {
  sm: `btn-${SIZES.sm}`,
  md: '',
  lg: `btn-${SIZES.lg}`,
};

const BUTTON_TYPE_CLASSES = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  success: 'btn-success',
  danger: 'btn-danger',
  warning: 'btn-warning',
  info: 'btn-info',
  regular: 'btn-light',
  dark: 'btn-dark',
  primaryOutline: 'btn-outline-primary',
  secondaryOutline: 'btn-outline-secondary',
  successOutline: 'btn-outline-success',
  dangerOutline: 'btn-outline-danger',
  warningOutline: 'btn-outline-warning',
  infoOutline: 'btn-outline-info',
  regularOutline: 'btn-outline-light',
  darkOutline: 'btn-outline-dark',
  link: 'btn-link', // Deemphasize a button by making it look like a link while maintaining button behavior
};

const BUTTON_GROUP_SIZE_CLASSES = {
  sm: `btn-group-${SIZES.sm}`,
  md: '',
  lg: `btn-group-${SIZES.lg}`,
};

const TYPE_ICONS = {
  time: 'clock',
  text: 'pen-alt',
  date: 'calendar',
  email: 'envelope',
  person: 'user',
  phone: 'phone',
  link: 'link',
  number: 'calculator',
};

const DEFAULT_FILTER_STATE = { query: '', status: '', sort: 'asc' };

const ALERT_TYPE_CLASSES = {
  primary: 'alert-primary',
  secondary: 'alert-secondary',
  success: 'alert-success',
  danger: 'alert-danger',
  warning: 'alert-warning',
  info: 'alert-info',
  regular: 'alert-light',
  dark: 'alert-dark',
};

const PROVINCES = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'PQ', label: 'Québec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
  { value: 'Other', label: 'Other' },
];

const PROVINCE_VALUES = PROVINCES.reduce((acc, { value, label }) => { acc[value] = label; return acc; }, {});

const LOCALES = {
  EN: 'en',
  FR: 'fr',
};

const LOCALE_VALUES = Object.values(LOCALES);

const LANGUAGE_PREFERENCES = {
  [LOCALES.EN]: 'English',
  [LOCALES.FR]: 'French',
};

const LANGUAGE_PREFERENCE_OPTIONS = Object.entries(LANGUAGE_PREFERENCES).map(([value, label]) => ({ value, label }));

const ACTIVE_STATUSES = [
  ['registered', 'Registered'],
  ['ready_to_go', 'Ready to Go'],
  ['in_session_logged_in', 'In Session'],
  ['in_extension', 'In extension'],
  ['completed', 'Completed'],
  ['removed', 'Removed'],
];

const ACTION_STATUSES = [
  'in_session_logged_in',
  'registered',
  'ready_to_go',
  'in_extension',
];

const GROUP_ACTIVE_STATES = [
  'registration_closed',
  'participant_lists_sent',
  'welcome_packs_sent',
  'in_session',
  'closed',
  'in_extension',
];

const GROUP_MEMBERSHIP_ACTION_OPTIONS = {
  WELCOME: 'resend_welcome',
  CONFIRMATION: 'resend_confirmation',
  CERTIFICATE: 'generate_certificate',
  PARE: 'resend_pare',
  IWL: 'resend_iwl',
  IMPERSONATE: 'impersonate',
  CURRICULUM_REVIEWER: 'curriculum_reviewer',
  INSUFFICIENT_PARTICIPATION: 'insufficient_participation',
  REMOVE: 'remove',
  CHANGE_STATE: 'change_state',
};

const REDUX_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  DUPLICATING: 'duplicating',
  ERROR: 'error',
};

const REDUX_SUCCESS = {
  CREATED: 'created',
  COPIED: 'copied',
  SAVED: 'saved',
  DELETED: 'deleted',
  ARCHIVED: 'archived',
  PUBLISHED: 'published',
  DUPLICATED: 'duplicated',
};

const COURSE_STATUS = {
  CLOSED: 'closed',
  DRAFT: 'draft',
  OPEN: 'open to registration',
  IN_SESSION: 'in session',
  ARCHIVED: 'archived',
  PUBLISHING: 'publishing',
  DUPLICATING: 'duplicating',
};

const TASK_TYPES = {
  PAGE: 'PageTask',
  MULTIPLE_CHOICE_QUIZ: 'MultipleChoiceQuizTask',
  POD_DISCUSSION: 'SubGroupDiscussionTask',
  JOURNAL: 'JournalTask',
  SURVEY: 'SurveyTask',
};

const TASK_TYPE_ICONS = {
  [TASK_TYPES.PAGE]: 'textarea',
  [TASK_TYPES.MULTIPLE_CHOICE_QUIZ]: 'checkCircle',
  [TASK_TYPES.POD_DISCUSSION]: 'chatQuote',
  [TASK_TYPES.JOURNAL]: 'book',
  [TASK_TYPES.SURVEY]: 'checkCircle',
};

const QUESTION_TYPES = {
  ESSAY: 'EssayQuestion',
  MULTIPLE_CHOICE: 'MultipleChoiceQuestion',
  JOURNAL: 'JournalQuestion',
  HEADING: 'QuestionHeading',
};

const QUESTION_TASK_TYPES = [
  TASK_TYPES.JOURNAL,
  TASK_TYPES.MULTIPLE_CHOICE_QUIZ,
  TASK_TYPES.SURVEY,
];

const DEFAULT_QUESTION = {
  id: null,
  taskId: null,
  type: null,
  body: '',
  explanation: '',
  answerRequired: false,
  bodyFr: '',
  explanationFr: '',
};

const PHYSICIAN_AFFILIATION = {
  MainproCredit: {
    code: 'CFPC',
    description: 'College of Family Physicians of Canada (CFPC)',
  },
  RcpscCredit: {
    code: 'RCPSC',
    description: 'Royal College of Physicians & Surgeons of Canada (RCPSC)',
  },
};

const PHYSICIAN_AFFILIATION_OPTIONS = Object.entries(PHYSICIAN_AFFILIATION).map(([key, { description }]) => ({
  value: key,
  label: description,
}));

const DATE_RANGE = {
  beginSessionAt: 'Groups started',
  closeSessionAt: 'Groups ended',
};

export const COMMENT_FLAGS = {
  IN_PROGRESS: 'In progress',
  CONSULT_REQUIRED: 'Consult required',
  ENGAGEMENT_SUPPORT: 'Engagement support',
  FLAGGED_FOR_REVIEW: 'Flagged for review',
  OTHER: 'Other',
};

export const FLAG_COLOURS = {
  [COMMENT_FLAGS.IN_PROGRESS]: 'text-accent-dark-blue',
  [COMMENT_FLAGS.CONSULT_REQUIRED]: 'text-danger',
  [COMMENT_FLAGS.ENGAGEMENT_SUPPORT]: 'text-accent-yellow',
  [COMMENT_FLAGS.FLAGGED_FOR_REVIEW]: 'text-warning',
  [COMMENT_FLAGS.OTHER]: 'text-accent-purple',
};

// app/interactions/services/group/update_extension_attrs.rb
// ensure_valid_group_membership_completions!
const EXTENDABLE_COMPLETION_STATES = [
  'completed_with_insufficient_participation',
  'dropout',
  'non_starter',
  'in_session_logged_in',
  'completed',
  'ready_to_go',
  'removed',
  'in_extension',
];

export const ACTIVATION = {
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
};

export const USER_ROLES = {
  isCurriculumReviewer: 'Curriculum Reviewer',
  isFacilitator: 'Facilitator',
  isHaRepresentative: 'HA Representative',
  isHarmTeamUser: 'Harm Team User',
  isIndigenousFacilitator: 'Indigenous Facilitator',
  isIndigenousPlatformLead: 'Indigenous Platform Lead',
  isSuperFacilitator: 'Lead Facilitator',
  isParticipant: 'Participant',
  isPareReviewer: 'PARE Reviewer',
  isRegistrar: 'Registrar',
  isReportingUser: 'Reporting User',
  isSuperAdmin: 'Super Administrator',
  isSuperCurriculumEditor: 'Super Curriculum Editor',
};

export {
  DEFAULT_TIMEOUT,
  SIZES,
  BUTTON_SIZE_CLASSES,
  BUTTON_TYPE_CLASSES,
  BUTTON_GROUP_SIZE_CLASSES,
  ALERT_TYPE_CLASSES,
  PROVINCES,
  PROVINCE_VALUES,
  LOCALES,
  LOCALE_VALUES,
  COUNTRIES,
  LANGUAGE_PREFERENCES,
  LANGUAGE_PREFERENCE_OPTIONS,
  ACTIVE_STATUSES,
  ACTION_STATUSES,
  GROUP_MEMBERSHIP_ACTION_OPTIONS,
  REDUX_STATUS,
  REDUX_SUCCESS,
  COURSE_STATUS,
  TASK_TYPES,
  TASK_TYPE_ICONS,
  QUESTION_TYPES,
  QUESTION_TASK_TYPES,
  DEFAULT_QUESTION,
  PHYSICIAN_AFFILIATION,
  PHYSICIAN_AFFILIATION_OPTIONS,
  DATE_RANGE,
  DEFAULT_FILTER_STATE,
  TYPE_ICONS,
  GROUP_ACTIVE_STATES,
  EXTENDABLE_COMPLETION_STATES,
};
