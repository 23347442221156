import React from 'react';
import PropTypes from 'prop-types';
import { StylePropType } from 'lib/propTypes';

const NOTIFICATION_COLOURS = {
  new: 'bg-info',
  aging: 'bg-warning',
  old: 'bg-danger',
  low: 'bg-info',
  medium: 'bg-warning',
  high: 'bg-danger',
};

function NotificationDot({ age, primary, className, style }) {
  const bgOpacity = primary ? undefined : 0.5;

  return (
    <span
      className={`${className} d-inline-block ${NOTIFICATION_COLOURS[age]}`}
      style={{
        ...style,
        borderRadius: 7,
        width: 7,
        height: 7,
        '--bs-bg-opacity': bgOpacity,
      }}
    />
  );
}

NotificationDot.defaultProps = {
  age: 'new',
  primary: true,
  className: '',
  style: {},
};

NotificationDot.propTypes = {
  age: PropTypes.oneOf(['new', 'aging', 'old', 'low', 'medium', 'high']),
  primary: PropTypes.bool,
  className: PropTypes.string,
  style: StylePropType,
};

export default NotificationDot;
