import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Comment from 'components/shared/Comments/Comment';
import { Button } from 'react-bootstrap';
import JournalHeader from 'components/facilitator/Journals/JournalHeader';
import JournalQuestion from 'components/shared/Journal/JournalQuestion';
import CommentCard from 'components/shared/Comments/CommentCard';
import SanyasIcon from 'components/shared/SanyasIcon';
import JournalAnswerCard from 'components/shared/Comments/JournalAnswerCard';
import { LOAD_PARTICIPANT_JOURNAL, LOAD_JOURNAL_ANSWERS, FLAG_JOURNAL } from 'store/facilitatorDashboard/actions';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import NotesPanel from 'components/facilitator/NotesPanel';
import ParticipantInfo from 'components/group_memberships/ParticipantInfo';

function JournalAnswersShowPage() {
  const params = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const { assignedFacilitatorId, questionIds, participantAnswerIds, participantAnswersById, commentIds, commentsById, journalPreviewsById } = useSelector((state) => state.facilitatorDashboard);
  const dispatch = useDispatch();
  const [isFlagged, setIsFlagged] = useState(false);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const getAnswerId = (questionId) => participantAnswerIds.find((id) => participantAnswersById[id].questionId === questionId) || '';
  const getCommentIds = (answerId) => commentIds.filter((id) => commentsById[id].commentableId === answerId);

  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const handleClose = () => setShowNotesPanel(false);
  const toggleShow = () => setShowNotesPanel((prev) => !prev);

  useEffect(() => {
    dispatch(LOAD_JOURNAL_ANSWERS.request(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!journalPreviewsById) {
      dispatch(LOAD_JOURNAL_ANSWERS.request(params));
    }
    dispatch(LOAD_PARTICIPANT_JOURNAL.request(params));
  }, [dispatch, journalPreviewsById, params]);

  useEffect(() => {
    if (participantAnswersById) {
      setIsFlagged(participantAnswersById[Object.keys(participantAnswersById)[0]].flagged);
    }
  }, [participantAnswersById]);

  const flagJournal = (userTaskId) => () => {
    setIsFlagged(!isFlagged);
    dispatch(FLAG_JOURNAL.request({
      subGroupId: params.subGroupId,
      gmId: params.groupMembershipId,
      userTaskId,
      taskId: params.taskId,
    }));
  };

  return (
    <FacilitatorTemplate className="bg-light">
      <JournalHeader className="mb-4" toggleNotes={toggleShow} />
      <NotesPanel context="userTask" contextId={participantAnswersById?.[participantAnswerIds?.[0]]?.userTaskId} show={showNotesPanel} onHide={handleClose} />

      {questionIds?.map((questionId, questionIndex) => {
        const answerId = getAnswerId(questionId);
        const answer = participantAnswersById[answerId];
        return (
          <div key={questionId} className={`row ${questionIndex > 0 ? 'border-top pt-4' : ''}`}>
            <div className="col-7 offset-2 mb-3">
              <JournalQuestion questionId={questionId} />

              <JournalAnswerCard answer={answer} />

              {getCommentIds(answerId).map((commentId) => <CommentCard key={commentId} answerId={answerId} commentId={commentId} isJournalComment />)}

              <div className="d-flex flex-column">
                <Comment answerId={answerId} taskId={params.taskId} subGroupId={params.subGroupId} isJournal />
              </div>
            </div>

            <div className="col-3">
              {/* HIDDEN UNTIL WORK CAN BE COMPLETED */}
              {/* <Button variant="outline-secondary" size="sm" className="d-block text-nowrap mb-2" disabled>
                <SanyasIcon className="me-1" name="star" />
                Starred
              </Button> */}

              {isAssignedFacilitationPoint ? (
                <Button variant="outline-secondary" size="sm" className="d-block text-nowrap mb-2" onClick={flagJournal(answer.userTaskId)}>
                  <SanyasIcon name="flag" className={`me-1 journal-flag ${isFlagged && 'text-warning'}`} />
                  Flag
                </Button>
              ) : (
                <div className="mb-2">
                  <span className="border border-secondary rounded bg-white py-1 px-2 text-small">
                    <SanyasIcon name="flag" className={`me-1 journal-flag ${isFlagged && 'text-warning'}`} />
                    Flag
                  </span>
                </div>
              )}

              {questionIndex === 0 && (
                <ParticipantInfo participantId={Number(params.groupMembershipId)} />
              )}
            </div>
          </div>
        );
      })}
    </FacilitatorTemplate>
  );
}

export default JournalAnswersShowPage;
