import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GET_CONVERSATION,
  START_COMPOSING_REPLY,
  CANCEL_COMPOSING_REPLY,
  CREATE_REPLY,
  CLEAR_SHOW_PAGE,
  SHOW_EDIT_SUBJECT,
  HIDE_EDIT_SUBJECT,
  CHANGE_SUBJECT,
  UPDATE_SUBJECT,
} from 'store/messages/actions';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import GroupConversation from 'components/facilitator/GroupConversation';
import OneOnOneConversation from 'components/facilitator/OneOnOneConversation';
import ConversationParticipant from 'components/facilitator/ConversationParticipant';
import ReplyArea from 'components/facilitator/ReplyArea';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';

function MessagesShowPage() {
  const showMessageHelper = useSelector((state) => state.messages.show);
  const { conversationId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GET_CONVERSATION.request({ conversationId }));
    return () => dispatch(CLEAR_SHOW_PAGE.action());
  }, [conversationId, dispatch]);

  const startComposingReply = () => {
    dispatch(START_COMPOSING_REPLY.action());
  };

  const cancelComposingReply = () => {
    dispatch(CANCEL_COMPOSING_REPLY.action());
  };

  const createReply = ({ message }) => {
    dispatch(CREATE_REPLY.request({ message }));
  };

  const showEditSubject = () => {
    dispatch(SHOW_EDIT_SUBJECT.action());
  };

  const hideEditSubject = () => {
    dispatch(HIDE_EDIT_SUBJECT.action());
  };

  const changeSubject = (subject) => {
    dispatch(CHANGE_SUBJECT.action(subject));
  };

  const updateSubject = () => {
    dispatch(UPDATE_SUBJECT.request());
  };

  if (!showMessageHelper.conversation) {
    return <FacilitatorTemplate className="messages-show-page" style={{ marginTop: 10 }}><Spinner message="Loading..." /></FacilitatorTemplate>;
  }
  const subject = showMessageHelper.newSubject || showMessageHelper.conversation.subject;
  return (
    <FacilitatorTemplate className="messages-show-page">
      <div className="row row-space-top row-space-bottom">
        { !showMessageHelper.editingSubject
            && (
            <h3>
              &ldquo;
              {subject}
              &rdquo;
              <button type="button" className="btn btn-light btn-sm ms-2" onClick={(e) => { e.preventDefault(); showEditSubject(); }}>Edit subject</button>
            </h3>
            )}
        { showMessageHelper.editingSubject
            && (
              <div className="col-md-12">
                <input type="text" style={{ width: '30%', display: 'inline-block' }} className="form-control input-sm" value={subject} onChange={(e) => { e.preventDefault(); changeSubject(e.target.value); }} />
                <button type="button" className="btn btn-primary btn-sm ms-2" onClick={(e) => { e.preventDefault(); updateSubject(); }}>Update</button>
                <button type="button" className="btn btn-link btn-sm ms-2" onClick={() => { hideEditSubject(); }}>Cancel</button>
              </div>
            )}
      </div>

      <Breadcrumbs
        navItems={
           [
             { label: 'Messages', href: '/facilitator/messages' },
             { label: subject, href: '#' },
           ]
         }
      />

      { !showMessageHelper.conversation.isAnnouncement
          && <ConversationParticipant fromUser={showMessageHelper.conversation.messages[0].fromUser} toUser={showMessageHelper.conversation.messages[0].toUser} />}

      { !showMessageHelper.composingReply && !showMessageHelper.submittingReply && !showMessageHelper.conversation.isAnnouncement
          && (
          <div style={{ textAlign: 'right' }}>
            <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); startComposingReply(); }}>Reply</button>
          </div>
          )}
      { (showMessageHelper.composingReply || showMessageHelper.submittingReply)
          && (
          <ReplyArea
            createReply={createReply}
            onCancel={cancelComposingReply}
            error={showMessageHelper.submitReplyError}
            submitting={showMessageHelper.submittingReply}
          />
          )}

      <div>
        { !showMessageHelper.conversation.isAnnouncement
            && <OneOnOneConversation conversation={showMessageHelper.conversation} />}

        { showMessageHelper.conversation.isAnnouncement
            && <GroupConversation conversation={showMessageHelper.conversation} />}

      </div>

    </FacilitatorTemplate>
  );
}

MessagesShowPage.defaultProps = {
};

MessagesShowPage.propTypes = {
};

export default MessagesShowPage;
