import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { CREATE_FACILITATOR_NOTE, UPDATE_FACILITATOR_NOTE } from 'store/facilitatorDashboard/actions';
import { selectNoteBySubgroupAndDiscussionTask, selectNoteBySubgroupAndJournalTask, selectNoteByUserTask } from 'store/facilitatorDashboard/selectors';
import SlideInPanel from 'components/shared/SlideInPanel';
import TextareaField from 'components/shared/FormFields/TextareaField';
import RichTextField from 'components/shared/FormFields/RichTextField';
import AutoSave from 'components/shared/AutoSave';

const NOTE_SELECTORS = {
  userTask: selectNoteByUserTask,
  journalTask: selectNoteBySubgroupAndJournalTask,
  discussionTask: selectNoteBySubgroupAndDiscussionTask,
};

const formikKey = (context, contextId, subGroupId, taskId) => {
  if (contextId) return `${context}-${contextId}`;
  return `${context}-${subGroupId}-${taskId}`;
};

function NotesPanel({ context, contextId, show, onHide }) {
  const { subGroupId, taskId, groupMembershipId } = useParams();
  const noteSelector = NOTE_SELECTORS[context];
  const note = useSelector((state) => noteSelector(state, contextId || { subGroupId, taskId }));
  const dispatch = useDispatch();

  const initialValues = { subGroupId: Number(subGroupId), taskId: Number(taskId), groupMembershipId: Number(groupMembershipId), ...note };
  const minHeight = window.innerHeight * 0.6;

  const handleSubmit = useCallback((values, actions) => {
    const ACTION = values.id ? UPDATE_FACILITATOR_NOTE : CREATE_FACILITATOR_NOTE;
    dispatch(ACTION.request(values, { formikActions: actions }));
  }, [dispatch]);

  return (
    <Formik
      key={formikKey(context, contextId, subGroupId, taskId)}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty }) => (
        <SlideInPanel
          title={(
            <>
              <h4 className="mb-1">Notes</h4>
              {dirty && (
                <span className="ms-auto pe-3">
                  <AutoSave debounceMs={1000} spinner />
                </span>
              )}
            </>
          )}
          show={show}
          onHide={onHide}
          scroll
          backdrop={false}
        >
          <Form>
            <TextareaField
              name="quicknotes"
              placeholder="Notes that help me remember why I prioritized it"
              helpText="These notes will be visible from the dashboard (limit 100 char)"
              maxLength={100}
              inline
              className="p-3"
            />

            <div className="border-top">
              <RichTextField variant="minimal" name="notes" hideLabel className="border-0" options={{ min_height: minHeight, statusbar: false }} />
            </div>
          </Form>
        </SlideInPanel>
      )}
    </Formik>
  );
}

NotesPanel.defaultProps = {
  contextId: null,
  show: false,
  onHide: null,
};

NotesPanel.propTypes = {
  context: PropTypes.oneOf(['userTask', 'journalTask', 'discussionTask']).isRequired,
  contextId: PropTypes.number,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default NotesPanel;
