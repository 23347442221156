import React from 'react';

function Journal() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="9" y="5" width="8" height="1.5" rx="0.75" fill="currentColor" />
      <rect x="9" y="8" width="8" height="1.5" rx="0.75" fill="currentColor" />
      <mask id="mask0_6291_18131" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="1" width="18" height="22">
        <path d="M4 4C4 2.34315 5.34315 1 7 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H7C5.34315 23 4 21.6569 4 20V4Z" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_6291_18131)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 24.5C22.4853 24.5 24.5 22.4853 24.5 20C24.5 17.5147 22.4853 15.5 20 15.5C17.5147 15.5 15.5 17.5147 15.5 20C15.5 22.4853 17.5147 24.5 20 24.5ZM20 26C23.3137 26 26 23.3137 26 20C26 16.6863 23.3137 14 20 14C16.6863 14 14 16.6863 14 20C14 23.3137 16.6863 26 20 26Z" fill="currentColor" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M19 2.5H7C6.17157 2.5 5.5 3.17157 5.5 4V4.06301C6.36261 4.28503 7 5.06808 7 6C7 6.93192 6.36261 7.71497 5.5 7.93699V10.063C6.36261 10.285 7 11.0681 7 12C7 12.9319 6.36261 13.715 5.5 13.937V16.063C6.36261 16.285 7 17.0681 7 18C7 18.9319 6.36261 19.715 5.5 19.937V20C5.5 20.8284 6.17157 21.5 7 21.5H19C19.8284 21.5 20.5 20.8284 20.5 20V4C20.5 3.17157 19.8284 2.5 19 2.5ZM4 20C2.89543 20 2 19.1046 2 18C2 16.8954 2.89543 16 4 16L4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10L4 8C2.89543 8 2 7.10457 2 6C2 4.89543 2.89543 4 4 4C4 2.34315 5.34315 1 7 1H19C20.6569 1 22 2.34315 22 4V20C22 21.6569 20.6569 23 19 23H7C5.34315 23 4 21.6569 4 20ZM4 5.5H5C5.27614 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.27614 6.5 5 6.5H4C3.72386 6.5 3.5 6.27614 3.5 6C3.5 5.72386 3.72386 5.5 4 5.5ZM4 11.5H5C5.27614 11.5 5.5 11.7239 5.5 12C5.5 12.2761 5.27614 12.5 5 12.5H4C3.72386 12.5 3.5 12.2761 3.5 12C3.5 11.7239 3.72386 11.5 4 11.5ZM5 17.5H4C3.72386 17.5 3.5 17.7239 3.5 18C3.5 18.2761 3.72386 18.5 4 18.5H5C5.27614 18.5 5.5 18.2761 5.5 18C5.5 17.7239 5.27614 17.5 5 17.5Z" fill="currentColor" />
    </svg>
  );
}

export default Journal;
