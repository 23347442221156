import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import Yup from 'lib/validation';
import { fetchGet } from 'lib/apiHelpers';
import { Alert, Button, Spinner } from 'react-bootstrap';
import InputField from 'components/shared/FormFields/InputField';
import CreateParticipantForm from 'components/groups/CreateParticipantForm/CreateParticipantForm';

const GetCheckEmail = (groupId, props) => fetchGet(`/api/registrar/groups/${groupId}/check_email.json`, props);

const FormSchemaEmail = Yup.object().shape({
  email: Yup.string().isValidEmail('Email address is not valid').required('Email address is required'),
});

const INITIAL_VALUES = {
  email: '',
};

const GroupParticipantsNewParticipant = ({ group }) => {
  const [checkedEmail, setEmailChecked] = useState(false);
  const [result, setResult] = useState(null);
  const [user, setUser] = useState(null);
  const formikRef = useRef();

  const handleSubmit = useCallback(async (values) => {
    const response = await GetCheckEmail(group.id, { email: values.email });

    if (response.result) {
      setResult(response.result);
    }

    if (response.user) {
      setUser(response.user);
    } else {
      setUser({
        email: values.email,
      });
    }
    setEmailChecked(true);
  }, [group.id]);

  const onReset = () => {
    setUser(null);
    setResult(null);
    setEmailChecked(false);
    formikRef.current?.resetForm();
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <p className="lead">
          Add a new participant to this group
        </p>

        {(checkedEmail && result.allowRegistration) && (
          <CreateParticipantForm group={group} user={user} />
        )}

        {(checkedEmail && result.errorCode === 'already_in_group') && (
          <>
            <Alert variant="success">
              The email address you entered belongs to a participant
              <a
                href={`/registrar/people/${result.userId}/group_memberships/${result.groupMembershipId}`}
                target="_blank"
                rel="noreferrer"
                className="alert-link px-1"
              >
                already in this group
              </a>
              .
            </Alert>

            <Button type="submit" className="btn btn-secondary" onClick={onReset}>
              Check another email address
            </Button>
          </>
        )}

        {(checkedEmail && result.errorCode === 'active_in_other') && (
          <>
            <Alert variant="warning">
              An
              <a
                href={`/registrar/people/${result.userId}/group_memberships/${result.otherGroupMembershipId}`}
                target="_blank"
                rel="noreferrer"
                className="alert-link px-1"
              >
                active group membership
              </a>
              already exists for that email address.
            </Alert>

            <Button type="submit" className="btn btn-secondary" onClick={onReset}>
              Check another email address
            </Button>
          </>
        )}

        {!checkedEmail && (
          <Formik
            onSubmit={handleSubmit}
            innerRef={formikRef}
            initialValues={INITIAL_VALUES}
            validationSchema={FormSchemaEmail}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <>
                <Form className="mt-3">
                  <InputField name="email" type="email" disabled={isSubmitting} label="Participant&rsquo;s email address" />

                  <div className="">
                    <Button type="submit" className="btn btn-primary me-2">
                      {isSubmitting ? 'Checking' : 'Next'}
                      {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                    </Button>
                    <Link to={`/registrar/groups/${group.id}`} className="btn btn-plain">Cancel</Link>
                  </div>
                </Form>

                <div className="form-text">
                  The system will check if this participant already has an account on the platform.
                </div>
              </>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

GroupParticipantsNewParticipant.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    groupParticipations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default GroupParticipantsNewParticipant;
