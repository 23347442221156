import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { GroupMembershipPropType } from 'lib/propTypes';
import Yup from 'lib/validation';
import { UPDATE_GROUP_MEMBERSHIP, LOAD_GROUP_MEMBERSHIPS_FORM_DATA } from 'store/groupMemberships/actions';
import { Button, Spinner as RBSpinner } from 'react-bootstrap';
import Spinner from 'components/shared/Spinner';
import ErrorMessage from 'components/shared/ErrorMessage';
import AlertDismissible from 'components/shared/AlertDismissible';
import SelectField from 'components/shared/FormFields/SelectField';
import InputField from 'components/shared/FormFields/InputField';
import PaymentDetails from 'components/users/PaymentDetails';

const formatHealthAuthorityLabel = ({ label, organization }, { context }) => {
  if (context === 'value') {
    return (
      <span>
        {label}
        <span className="ms-2 text-small text-secondary">{organization}</span>
      </span>
    );
  }
  return label;
};

const StandardFormSchema = Yup.object().shape({
  genderId: Yup.string().required('Gender is required'),
  ageGroupId: Yup.string().required('Age group is required'),
  educationLevelId: Yup.string().required('Education level is required'),
  ancestryId: Yup.string().required('Ancestry is required'),
  healthAuthorityId: Yup.string().required('Health authority is required'),
  jobCategoryId: Yup.string().required('Current position is required'),
  jobCategoryOther: Yup.string()
    .when('jobCategoryId', {
      is: (jobCategoryId) => parseInt(jobCategoryId, 10) === 24, // Other
      then: Yup.string()
        .required('Description is required'),
    }),
  authorizerEmail: Yup.string().isValidEmail('Email address is not valid'),
});
const RegistrarFormSchema = Yup.object().shape({
  healthAuthorityId: Yup.string().required('Health authority is required'),
  jobCategoryOther: Yup.string()
    .when('jobCategoryId', {
      is: (jobCategoryId) => parseInt(jobCategoryId, 10) === 24, // Other
      then: Yup.string()
        .required('Description is required'),
    }),
  authorizerEmail: Yup.string().isValidEmail('Email address is not valid'),
});

function GroupMembershipForm({ groupMembership }) {
  const currentUser = useSelector((state) => state.currentUser);
  const {
    genderId = '',
    ageGroupId = '',
    educationLevelId = '',
    ancestryId = '',
    serviceOrganization = '',
    department = '',
    healthAuthorityId = '',
    jobCategoryId = '',
    jobCategoryOther = '',
    physicianCreditType = '',
    employeeNumber = '',
    authorizerName = '',
    authorizerEmail = '',
    authorizerPhone = '',
    id,
    userId,
    organizationId,
  } = groupMembership;

  const organizations = useSelector((state) => Object.values(state.organizations.byId));
  const healthAuthorities = useSelector((state) => state.healthAuthorities.byId);
  const { formData, success: gmSuccess, error: gmError } = useSelector((state) => state.groupMemberships);
  const healthAuthorityLabel = organizations.find((o) => o.id === organizationId)?.healthAuthorityLabel ?? 'Health Authority';
  const dispatch = useDispatch();

  const groupedHealthAuthorities = useMemo(() => {
    if (!organizations || !healthAuthorities) return [];

    return organizations.reduce((group, org) => {
      group.push({ label: org.name,
        options: org.healthAuthorityIds.map((haId) => {
          const ha = healthAuthorities[haId] || {};
          return ({ value: ha.id, label: ha.name, organization: org.shortName });
        }),
      });
      return group;
    }, []);
  }, [organizations, healthAuthorities]);

  // ensure null is cast to empty string
  const initialValues = {
    genderId: genderId ?? '',
    ageGroupId: ageGroupId ?? '',
    educationLevelId: educationLevelId ?? '',
    ancestryId: ancestryId ?? '',
    serviceOrganization: serviceOrganization ?? '',
    department: department ?? '',
    healthAuthorityId: healthAuthorityId ?? '',
    jobCategoryId: jobCategoryId ?? '',
    jobCategoryOther: jobCategoryOther ?? '',
    physicianCreditType: physicianCreditType ?? '',
    employeeNumber: employeeNumber ?? '',
    authorizerName: authorizerName ?? '',
    authorizerEmail: authorizerEmail ?? '',
    authorizerPhone: authorizerPhone ?? '',
    id,
    userId,
  };

  const handleSubmit = useCallback((values, actions) => {
    const nullified = Object.keys(values).reduce((r, k) => {
      if (values[k] === '' && k !== 'physicianCreditType') return ({ ...r, [k]: null });
      return r;
    }, {});
    dispatch(UPDATE_GROUP_MEMBERSHIP.request({ ...values, ...nullified }, { formikActions: actions }));
  }, [dispatch]);

  useEffect(() => {
    if (!formData.data && formData.status !== REDUX_STATUS.PENDING) {
      dispatch(LOAD_GROUP_MEMBERSHIPS_FORM_DATA.request());
    }
  }, [formData.data, formData.status, dispatch]);

  return (
    <>
      {formData.status === REDUX_STATUS.PENDING && <Spinner />}
      {formData.status !== REDUX_STATUS.PENDING && (
        <>
          <ErrorMessage error={gmError} className="mt-2" />

          {gmSuccess === REDUX_SUCCESS.SAVED && (
            <AlertDismissible className="mt-3">
              Group membership saved successfully.
            </AlertDismissible>
          )}

          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={currentUser?.isRegistrar ? RegistrarFormSchema : StandardFormSchema}
            enableReinitialize
          >
            {({ values, dirty, isSubmitting }) => (
              <Form className="row">
                <div className="d-flex align-items-center">
                  <h2 className="mb-0">Participant info</h2>
                  <Button type="submit" className="ms-auto" disabled={!dirty || isSubmitting}>
                    Save
                    {isSubmitting && <RBSpinner size="sm" className="ms-1" animation="border" role="status" />}
                  </Button>
                </div>

                <fieldset className="mb-4">
                  <legend className="fs-6 fw-semibold">Demographic information</legend>

                  <div className="row">
                    <SelectField
                      className="col-sm-6"
                      label="Gender"
                      name="genderId"
                      includeBlank="Please select gender"
                      options={formData.data?.gender}
                      required={!currentUser?.isRegistrar}
                    />

                    <SelectField
                      className="col-sm-6"
                      label="Ancestry"
                      name="ancestryId"
                      includeBlank="Please select ancestry"
                      options={formData.data?.ancestry}
                      required={!currentUser?.isRegistrar}
                    />

                    <SelectField
                      className="col-sm-6"
                      label="Age group"
                      name="ageGroupId"
                      includeBlank="Please select age group"
                      options={formData.data?.ageGroup}
                      required={!currentUser?.isRegistrar}
                    />

                    <SelectField
                      className="col-sm-6"
                      label="Education level"
                      name="educationLevelId"
                      includeBlank="Please select education level"
                      options={formData.data?.educationLevel}
                      required={!currentUser?.isRegistrar}
                    />
                  </div>
                </fieldset>

                <fieldset className="mb-4">
                  <legend className="fs-6 fw-semibold">Organization information</legend>

                  <div className="row">
                    <InputField className="col-sm-6" name="serviceOrganization" />

                    <InputField className="col-sm-6" name="department" />

                    <SelectField
                      className="col-sm-6"
                      label={healthAuthorityLabel}
                      name="healthAuthorityId"
                      includeBlank={`Please select ${healthAuthorityLabel}`}
                      options={groupedHealthAuthorities}
                      required
                      formatOptionLabel={formatHealthAuthorityLabel}
                    />

                    <SelectField
                      className="col-sm-6"
                      label="Current Position"
                      name="jobCategoryId"
                      includeBlank="Please select position"
                      options={formData.data?.jobCategory}
                      required={!currentUser?.isRegistrar}
                    />

                    <InputField className="col-sm-6" name="employeeNumber" />

                    {values.jobCategoryId === 24 && ( // jobCategoryId 24 === "Other"
                      <InputField
                        className="col-sm-6"
                        label="Please describe your Current Position"
                        name="jobCategoryOther"
                      />
                    )}

                    {values.jobCategoryId === 15 && ( // jobCategoryId 15 === "Physician"
                      <SelectField
                        className="col-sm-6"
                        label="Affiliation"
                        name="physicianCreditType"
                        helpText="To be eligible for continuing professional development credits (CPD), please choose your professional affiliation."
                        options={formData.data?.physicianCreditType}
                      />
                    )}
                  </div>
                </fieldset>

                {currentUser?.isRegistrar && (
                  <>
                    <fieldset className="mb-4">
                      <legend className="fs-6 fw-semibold">Manager information</legend>

                      <div className="row">
                        <InputField className="col-sm-6" name="authorizerName" />

                        <InputField className="col-sm-6" name="authorizerEmail" type="email" />

                        <InputField className="col-sm-6" name="authorizerPhone" type="tel" />
                      </div>
                    </fieldset>

                    <fieldset className="mb-4">
                      <legend className="fs-6 fw-semibold">Payment information</legend>

                      <div className="row">
                        <div className="col-sm-6">
                          <PaymentDetails payment={groupMembership.trainingPayment} voucher={groupMembership.voucher} />
                        </div>
                      </div>
                    </fieldset>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}

GroupMembershipForm.propTypes = {
  groupMembership: GroupMembershipPropType.isRequired,
};

export default GroupMembershipForm;
