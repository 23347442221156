import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GroupMembershipPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { titleCase } from 'lib/utils';
import { selectJournalDataByParticipantId } from 'store/groupShow/selectors';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import SanyasIcon from 'components/shared/SanyasIcon';
import JournalDot from './JournalDot';

const tooltipText = (journalData) => {
  if (!journalData) return null;

  let text = '';

  if (journalData.primaryJournalNotificationCount > 0) {
    text = 'Primary journal answer is unread';
  }

  if (journalData.secondaryJournalNotificationCount > 0) {
    if (text) text += ', and ';
    text += `${journalData.secondaryJournalNotificationCount} unread secondary journal comment${journalData.secondaryJournalNotificationCount > 1 ? 's' : ''}`;
  }

  return (
    <>
      <br />
      {text}
    </>
  );
};

function JournalDetails({ gm }) {
  const journalData = useSelector((state) => selectJournalDataByParticipantId(state, gm.id));

  return (
    <li className="py-2 px-3">
      <SimpleToolTip
        text={(
          <span className="text-small">
            {titleCase(gm.completion)}
            {tooltipText(journalData)}
          </span>
      )}
        placement="top"
        width={240}
        innerClassName="d-flex align-items-center"
        innerStyle={{ width: 'fit-content' }}
      >
        <Link
          to={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: gm.id })}
          className="text-decoration-none"
        >
          {`${gm.firstName} ${gm.lastName}`}
        </Link>

        {journalData?.isFlaggedForFollowup && (
          <SanyasIcon name="flag" className="ms-2 text-danger" />
        )}

        <JournalDot data={journalData} className="ms-2" primary />
        <JournalDot data={journalData} className="ms-2" />
      </SimpleToolTip>
    </li>
  );
}

JournalDetails.propTypes = {
  gm: GroupMembershipPropType.isRequired,
};

export default JournalDetails;
