import { takeLatest, put, select, take, call } from 'redux-saga/effects';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { GET_CURRENT_USER, GET_CURRENT_USER_NOTIFICATIONS } from 'store/actions';

export function* waitFor(selector) {
  if (yield select(selector)) return; // (1)

  while (true) {
    yield take('*'); // (1a)
    if (yield select(selector)) return; // (1b)
  }
}

export default function* sagas() {
  yield takeLatest(GET_CURRENT_USER.REQUEST, function* getCurrentUser() {
    const state = yield select();
    if (!state.currentUser) {
      try {
        const { data } = yield fetchGet('/api/user', {});
        yield put(GET_CURRENT_USER.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_CURRENT_USER);
      }
    } else {
      yield put(GET_CURRENT_USER.success({}, { cached: true }));
    }
  });

  yield takeLatest(GET_CURRENT_USER_NOTIFICATIONS.REQUEST, function* getCurrentUserNotifications() {
    yield call(waitFor, (state) => state.currentUser); // ensure currentUser is loaded
    const { currentUser } = yield select();
    if (currentUser?.isFacilitator) {
      try {
        const data = yield fetchGet('/api/facilitator/notifications');
        yield put(GET_CURRENT_USER_NOTIFICATIONS.success(data));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_CURRENT_USER_NOTIFICATIONS);
      }
    }
  });
}
