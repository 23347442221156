import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { SHORT_DATE } from 'lib/dateFormats';
import { DiscussionBoardNotificationPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH } from 'lib/routerPaths';
import Badge from 'components/shared/Badge/Badge';
import CommentFlag from 'components/shared/Comments/CommentFlag';
import QuicknotesPopover from '../QuicknotesPopover';

function DiscussionBoardRow({ discussionBoard }) {
  const {
    internalName,
    groupName,
    subGroupName,
    groupBeginSessionAt,
    groupCloseSessionAt,
    groupExtendedUntil,
    highPriorityTotal,
    mediumPriorityTotal,
    lowPriorityTotal,
    discussionBoardId: discussionTaskId,
    groupId: id,
    subGroupId,
    flags,
    note,
  } = discussionBoard;

  return (
    <tr key={discussionTaskId} className="text-small">
      <td className="pe-0">
        {flags?.map((flag) => <CommentFlag key={discussionTaskId + flag} flag={flag} />)}
      </td>

      <td>
        <Badge
          className="ms-1"
          variant={internalName.toLowerCase()}
          title={internalName}
        />
      </td>
      <td className="fw-semibold">{groupName}</td>
      <td>{subGroupName}</td>
      <td className="text-nowrap">
        {format(parseISO(groupBeginSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        {format(parseISO(groupCloseSessionAt), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        {!!groupExtendedUntil && format(parseISO(groupExtendedUntil), SHORT_DATE)}
      </td>
      <td className="text-nowrap">
        <Badge
          variant="red"
          title={highPriorityTotal}
          className="me-1"
        />
        <Badge
          variant="orange"
          title={mediumPriorityTotal}
          className="me-1"
        />
        <Badge
          variant="green"
          title={lowPriorityTotal}
          className="me-1"
        />
      </td>
      <td>
        <QuicknotesPopover note={note} />
      </td>
      <td>
        <Link to={buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id, subGroupId, taskId: discussionTaskId })}>
          View
        </Link>
      </td>
    </tr>
  );
}

DiscussionBoardRow.propTypes = {
  discussionBoard: DiscussionBoardNotificationPropType.isRequired,
};

export default DiscussionBoardRow;
