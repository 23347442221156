import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATUS } from 'lib/constants';
import useGroupsChannel from 'lib/hooks/useGroupsChannel';
import { buildRoutePath, getSearchParams } from 'lib/routerHelpers';
import { REGISTRAR_GROUP_DISCUSSIONS_PATH, REGISTRAR_GROUP_EMAIL_TEMPLATES_PATH, REGISTRAR_GROUP_JOURNALS_PATH, REGISTRAR_GROUP_REPORTS_PATH, REGISTRAR_GROUP_RESERVATIONS_PATH, REGISTRAR_GROUP_SETTINGS_PATH, REGISTRAR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import { GET_GROUP } from 'store/groupShow/actions';
import Spinner from 'components/shared/Spinner';
import Header from 'components/groups/Header/Header';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import GroupDiscussionsTabPage from 'pages/groups/GroupDiscussionsTabPage';
import GroupJournalsTabPage from 'pages/groups/GroupJournalsTabPage';
import GroupParticipantsTabPage from 'pages/groups/GroupParticipantsTabPage';
import GroupReportsTabPage from 'pages/groups/GroupReportsTabPage';
import GroupSettingsTabPage from 'pages/groups/GroupSettingsTabPage';
import GroupSettingsReservationsPage from 'pages/groups/GroupSettingsReservationsPage';
import GroupEmailTemplatesTabPage from 'pages/groups/GroupEmailTemplatesTabPage';

const participantRoutes = [
  REGISTRAR_GROUP_SHOW_PATH,
  `${REGISTRAR_GROUP_SHOW_PATH}/extend`,
  `${REGISTRAR_GROUP_SHOW_PATH}/new`,
];

function GroupShowPage() {
  const { id } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const { group, status } = useSelector((state) => state.groupShow);
  const dispatch = useDispatch();
  const groupShowPath = buildRoutePath(REGISTRAR_GROUP_SHOW_PATH, { id });
  const isNew = useMemo(() => getSearchParams(search, 'new', false), [search]);

  useGroupsChannel();

  useEffect(() => {
    if (!group || group.id !== Number(id)) {
      dispatch(GET_GROUP.request(id));
    }
  }, [group, id, dispatch]);

  useEffect(() => {
    if (isNew) {
      dispatch(GET_GROUP.request(id));
      history.replace({ search: null }); // remove the `new` flag after loading
    }
  }, [isNew, id, dispatch, history]);

  return (
    <RegistrarTemplate>
      <div className="header flush pb-0">
        <Header />

        <TabbedNav
          variant="underline"
          navItems={[
            {
              title: 'Participants',
              href: groupShowPath,
              match: {
                path: participantRoutes,
                exact: true,
              },
              isActive: (match, location) => {
                const participantPaths = participantRoutes.map((route) => buildRoutePath(route, { id }));
                return !!match || participantPaths.includes(location.pathname);
              },
            },
            {
              title: 'Reports',
              href: `${groupShowPath}/reports`,
              match: { path: REGISTRAR_GROUP_REPORTS_PATH },
            },
            {
              title: 'Discussions',
              href: `${groupShowPath}/discussions`,
              match: { path: REGISTRAR_GROUP_DISCUSSIONS_PATH },
            },
            {
              title: 'Journals',
              href: `${groupShowPath}/journals`,
              match: { path: REGISTRAR_GROUP_JOURNALS_PATH },
            },
            {
              title: 'Reservations',
              href: `${groupShowPath}/reservations`,
              match: { path: REGISTRAR_GROUP_RESERVATIONS_PATH },
            },
            {
              title: 'Settings',
              href: `${groupShowPath}/settings`,
              match: { path: REGISTRAR_GROUP_SETTINGS_PATH },
            },
            {
              title: 'Email templates',
              href: `${groupShowPath}/email_templates`,
              match: { path: REGISTRAR_GROUP_EMAIL_TEMPLATES_PATH },
            },
          ]}
        />
      </div>

      <div className="flush my-4 px-4">
        {(!group || status === REDUX_STATUS.PENDING) && (
          <Spinner message="Loading group..." />
        )}

        {(group && [REDUX_STATUS.IDLE, REDUX_STATUS.SUCCESS].includes(status)) && (
          <Switch>
            <Route path={REGISTRAR_GROUP_REPORTS_PATH}>
              <GroupReportsTabPage group={group} />
            </Route>
            <Route path={REGISTRAR_GROUP_DISCUSSIONS_PATH}>
              <GroupDiscussionsTabPage group={group} />
            </Route>
            <Route path={REGISTRAR_GROUP_JOURNALS_PATH}>
              <GroupJournalsTabPage group={group} />
            </Route>
            <Route path={REGISTRAR_GROUP_RESERVATIONS_PATH}>
              <GroupSettingsReservationsPage group={group} />
            </Route>
            <Route path={REGISTRAR_GROUP_SETTINGS_PATH}>
              <GroupSettingsTabPage group={group} />
            </Route>
            <Route path={REGISTRAR_GROUP_EMAIL_TEMPLATES_PATH}>
              <GroupEmailTemplatesTabPage group={group} />
            </Route>

            <Route path="*">
              <GroupParticipantsTabPage group={group} />
            </Route>
          </Switch>
        )}
      </div>
    </RegistrarTemplate>
  );
}

export default GroupShowPage;
