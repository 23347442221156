import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { RefPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, FACILITATOR_GROUP_LIVE_FEED_PATH, FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import SanyasIcon from 'components/shared/SanyasIcon';

const TASK_TYPES = {
  SubGroupDiscussionTask: 'Pod Discussion',
  JournalTask: 'Journal',
};

function LiveFeedItem({ notificationId, innerRef }) {
  const { path } = useRouteMatch();
  const notificationStore = path === FACILITATOR_GROUP_LIVE_FEED_PATH ? 'groupShow' : 'facilitatorDashboard';
  const notification = useSelector((state) => state[notificationStore].liveFeed?.byId?.[notificationId]);

  if (!notification) {
    return null;
  }

  return (
    <div ref={innerRef} className="card border mb-3">
      <div className="card-header d-flex align-items-center justify-content-between">
        <span>{notification.taskName}</span>
        <span className="badge rounded-pill bg-light text-secondary">{TASK_TYPES[notification.taskType]}</span>
      </div>

      <div className="card-body">
        <div className="d-flex">
          <div className="me-3">
            {(notification.taskType === 'SubGroupDiscussionTask') && (
              <SanyasIcon name="discussion" className="me-2 text-accent-land" size="2x" />
            )}
            {(notification.taskType === 'JournalTask') && (
              <SanyasIcon name="journal" className="me-2 text-accent-purple" size="2x" />
            )}
          </div>

          <div className="flex-grow-1">
            <h5 className="card-title">
              {(notification.taskType === 'SubGroupDiscussionTask') && (
                <Link
                  to={buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id: notification.groupId, subGroupId: notification.subGroupId, taskId: notification.taskId })}
                  className="stretched-link"
                >
                  {notification.body}
                </Link>
              )}

              {(notification.taskType === 'JournalTask') && (
                <Link
                  to={buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id: notification.groupId, subGroupId: notification.subGroupId, taskId: notification.taskId, groupMembershipId: notification.triggerGroupMembershipId })}
                  className="stretched-link"
                >
                  {notification.body}
                </Link>
              )}
            </h5>

            <h6 className="card-subtitle mb-2 text-muted">
              {formatDistanceToNow(parseISO(notification.createdAt), { addSuffix: true })}
            </h6>

            <p className="card-text">{notification.excerpt}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

LiveFeedItem.defaultProps = {
  innerRef: null,
};

LiveFeedItem.propTypes = {
  notificationId: PropTypes.number.isRequired,
  innerRef: RefPropType,
};

export default LiveFeedItem;
