import PreviewJournalAnswersTable from 'components/facilitator/PreviewJournalAnswersTable';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LOAD_JOURNAL_ANSWERS } from 'store/facilitatorDashboard/actions';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import JournalHeader from 'components/facilitator/Journals/JournalHeader';
import NotesPanel from 'components/facilitator/NotesPanel';

function JournalsPreviewShowPage() {
  const params = useParams();
  const dispatch = useDispatch();

  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const handleClose = () => setShowNotesPanel(false);
  const toggleShow = () => setShowNotesPanel((prev) => !prev);

  useEffect(() => {
    dispatch(LOAD_JOURNAL_ANSWERS.request(params));
  }, [dispatch, params]);

  return (
    <FacilitatorTemplate className="bg-light">
      <JournalHeader className="mb-4" toggleNotes={toggleShow} />
      <NotesPanel context="journalTask" show={showNotesPanel} onHide={handleClose} />

      <PreviewJournalAnswersTable />
    </FacilitatorTemplate>
  );
}

export default JournalsPreviewShowPage;
