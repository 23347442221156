import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ExtendedParticipantPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import Spinner from 'components/shared/Spinner';
import './styles.scss';

function ExtendedParticipantsList({ participants }) {
  if (!participants) {
    return <div className="col-md-4 text-center"><Spinner /></div>;
  }
  if (participants.length === 0) {
    return <div className="col-md-4" />;
  }
  participants.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
  return (
    <div className="col-md-4 text-start">
      <h5 className="mt-0">Extended Participants</h5>
      {
        participants.map((participant) => (
          <span key={participant.groupMembershipId} className="list-inline-dot">
            <Link
              to={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: participant.groupMembershipId })}
            >
              {participant.fullName}
            </Link>
          </span>
        ))
      }
    </div>
  );
}

ExtendedParticipantsList.defaultProps = {
  participants: [],
};

ExtendedParticipantsList.propTypes = {
  participants: PropTypes.arrayOf(ExtendedParticipantPropType),
};

export default ExtendedParticipantsList;
