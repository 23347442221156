import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FACILITATOR_JOURNALS_PATH } from 'lib/routerPaths';
import { CHANGE_CURRENT_FACILITATOR, CHANGE_DASHBOARD_SORT_ORDER } from 'store/facilitatorDashboard/actions';
import { SORT_ORDER_TYPES } from 'store/facilitatorDashboard/reducer';
import { activatedFacilitators } from 'store/facilitatorDashboard/selectors';

function FacilitatorHeader({ totalPosts }) {
  const facilitators = useSelector((state) => activatedFacilitators(state));
  const { currentFacilitator, sortOrder } = useSelector((state) => state.facilitatorDashboard);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleCurrentFacilitatorChange = useCallback((e) => {
    dispatch(CHANGE_CURRENT_FACILITATOR.action({ id: e.target.value }));
  }, [dispatch]);

  const handleSortOrderChange = useCallback((e) => {
    dispatch(CHANGE_DASHBOARD_SORT_ORDER.action({ sortLabel: e.target.value }));
  }, [dispatch]);

  return (
    <div className="row align-items-center mt-4">
      <div className="col-md-3">
        <label className="fw-semibold text-small" htmlFor="dashboard-facilitator-select">View as</label>
        <select
          id="dashboard-facilitator-select"
          onChange={handleCurrentFacilitatorChange}
          value={currentFacilitator?.id}
          className="form-select switch-facilitator"
        >
          <option disabled="disabled" value="">Choose Facilitator</option>
          {facilitators.length > 0 && facilitators.map((facilitator) => (
            <option key={facilitator.id} value={facilitator.id}>
              {facilitator.fullName}
            </option>
          ))}
        </select>
      </div>

      <div className="col-md-4">
        <label className="fw-semibold text-small" htmlFor="dashboard-sort-order">Sort by</label>
        <select
          id="dashboard-sort-order"
          onChange={handleSortOrderChange}
          className="form-select switch-start-date inline-block"
          value={sortOrder}
        >
          {Object.values(SORT_ORDER_TYPES).map((sortOrderType) => (
            <option key={sortOrderType.value} value={sortOrderType.value}>{sortOrderType.label}</option>
          ))}
        </select>
      </div>

      {location.pathname === FACILITATOR_JOURNALS_PATH && (
        <div className="col fw-accent text-end text-xsmall text-secondary">
          <span className="me-1 text-accent-deep-grey text-uppercase">
            Total posts:
          </span>
          {totalPosts}
        </div>
      )}
    </div>
  );
}

FacilitatorHeader.defaultProps = {
  totalPosts: 0,
};

FacilitatorHeader.propTypes = {
  totalPosts: PropTypes.number,
};

export default FacilitatorHeader;
