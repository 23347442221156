import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { buildRoutePath } from 'lib/routerHelpers';
import { sentenceCase } from 'lib/utils';
import { AnswerPropType } from 'lib/propTypes';
import { FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import { Button } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import Badge from 'components/shared/Badge/Badge';
import JournalPreviewCard from 'components/shared/Comments/JournalPreviewCard';
import NotificationDot from 'components/shared/NotificationDot';
import QuicknotesPopover from '../QuicknotesPopover';
import './JournalRow.scss';

function AnswerRow({ answer, internalName, journalTaskId }) {
  const [showAnswer, setShowAnswer] = useState(false);
  const { subGroupId, taskId, id } = useParams();
  const { triggerGmIds } = useSelector((state) => state.facilitatorDashboard.subGroups[subGroupId].journalNotifications[taskId]);

  const showNotification = triggerGmIds.includes(answer.participationId);

  return (
    <>
      <tr key={journalTaskId} className="text-small">
        <td className="pe-0 align-content-center">
          <div className="d-flex align-items-center">
            <div className="ps-2 col-2" style={{ paddingRight: '' }}>
              {showNotification && (
                <NotificationDot age={answer.notificationPriority} />
              )}
            </div>
            {answer.flagged && <SanyasIcon size="lg" className="ms-3 text-warning col-2" name="flag" />}
          </div>
        </td>
        <td className="pe-0 align-content-center">
          <Badge
            className="ms-1"
            variant={internalName.toLowerCase()}
            title={internalName}
          />
        </td>
        <td className="pe-0 text-nowrap align-content-center">{answer.fullName}</td>
        <td className="pe-0 align-content-center">{formatDistanceToNow(parseISO(answer.createdAt), { addSuffix: true })}</td>
        <td className="pe-0 align-content-center">{`${sentenceCase(answer.completion)} ${answer.percentageDoneCourse}%`}</td>
        <td>
          <div className="d-flex justify-content-evenly">
            <QuicknotesPopover note={answer.note} />
            {/* HIDDEN UNTIL WORK CAN BE COMPLETED */}
            {/* <Button variant={null} className="text-accent-grey journal-row-buttons" onClick={() => null}>
              <SanyasIcon size="xl" name="star" />
            </Button> */}
            <Link className="pt-1 text-decoration-none text-uppercase" to={buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id, subGroupId, taskId, groupMembershipId: answer.participationId })}>
              View
            </Link>
            <Button variant={null} className="journal-row-buttons text-accent-land" onClick={() => setShowAnswer(!showAnswer)}>
              <SanyasIcon size="xl" name="chevronDown" />
            </Button>
          </div>
        </td>
      </tr>
      {showAnswer && (
      <tr>
        <td style={{ width: '100%' }} className="pe-0" colSpan="6">
          <JournalPreviewCard answer={answer} />
        </td>
      </tr>
      )}
    </>
  );
}

AnswerRow.propTypes = {
  answer: AnswerPropType.isRequired,
  internalName: PropTypes.string.isRequired,
  journalTaskId: PropTypes.string.isRequired,
};

export default AnswerRow;
