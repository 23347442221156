import PropTypes from 'prop-types';
import { ACTIVATION, COURSE_STATUS, LANGUAGE_PREFERENCES, LOCALES, REDUX_STATUS, TASK_TYPES } from './constants';

export const StylePropType = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
);

// Any JSX component or HTML element (e.g. <div>, <span>, <MyComponent />)
export const ComponentPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
  PropTypes.element,
]);

export const RefPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

export const StatusPropType = PropTypes.oneOf(Object.values(REDUX_STATUS));

export const ErrorPropType = PropTypes.shape({
  name: PropTypes.string,
  message: PropTypes.string,
  details: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
    errors: PropTypes.shape({
      user: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
  }),
});

export const MessagePropType = PropTypes.shape({
  id: PropTypes.number,
  fromUserId: PropTypes.number,
  toUserId: PropTypes.number,
  body: PropTypes.string,
  deliveredAt: PropTypes.string,
  readAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  conversationId: PropTypes.number,
  originalMessageId: PropTypes.number,
});

export const ConversationPropType = PropTypes.shape({
  id: PropTypes.number,
  subject: PropTypes.string,
  isAnnouncement: PropTypes.bool,
  messages: PropTypes.arrayOf(MessagePropType),
});

const JournalNotificationTypePropType = PropTypes.shape({
  highPriorityTotal: PropTypes.number,
  mediumPriorityTotal: PropTypes.number,
  lowPriorityTotal: PropTypes.number,
  notificationIds: PropTypes.arrayOf(PropTypes.number),
});

export const JournalNotificationPropType = PropTypes.shape({
  journalTaskId: PropTypes.number,
  position: PropTypes.string,
  name: PropTypes.string,
  internalName: PropTypes.string,
  silenceJournalNotification: PropTypes.bool,
  primary: JournalNotificationTypePropType,
  replies: JournalNotificationTypePropType,
  subGroupId: PropTypes.number,
  subGroupName: PropTypes.string,
  groupId: PropTypes.number,
  groupName: PropTypes.string,
  groupBeginSessionAt: PropTypes.string,
  groupCloseSessionAt: PropTypes.string,
  groupExtendedUntil: PropTypes.string,
  responseRate: PropTypes.number,
  flags: PropTypes.arrayOf(PropTypes.string),
});

export const AnswerPropType = PropTypes.shape({
  answerId: PropTypes.number,
  participationId: PropTypes.number,
  answer: PropTypes.string,
  flagged: PropTypes.bool,
  fullName: PropTypes.string,
  createdAt: PropTypes.string,
  completion: PropTypes.string,
  percentageDoneCourse: PropTypes.number,
});

export const DiscussionBoardNotificationPropType = PropTypes.shape({
  discussionBoardId: PropTypes.number,
  discussionBoardName: PropTypes.string,
  subGroupId: PropTypes.number,
  subGroupName: PropTypes.string,
  position: PropTypes.string,
  highPriorityTotal: PropTypes.number,
  mediumPriorityTotal: PropTypes.number,
  lowPriorityTotal: PropTypes.number,
  groupId: PropTypes.number,
  groupName: PropTypes.string,
  groupBeginSessionAt: PropTypes.string,
  groupCloseSessionAt: PropTypes.string,
  flags: PropTypes.arrayOf(PropTypes.string),
});

export const InsufficientParticipationPropType = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  groupMembershipId: PropTypes.number.isRequired,
  insufficientParticipationMarkedAt: PropTypes.string.isRequired,
});

export const ExtendedParticipantPropType = PropTypes.shape({
  fullName: PropTypes.string,
  groupId: PropTypes.number,
  groupMembershipId: PropTypes.number,
});

export const FlaggedJournalPropType = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  groupMembershipId: PropTypes.number.isRequired,
  taskId: PropTypes.number.isRequired,
});

export const SubgroupNotificationsPropType = PropTypes.shape({
  subGroupId: PropTypes.number,
  subGroupName: PropTypes.string,
  groupId: PropTypes.number,
  courseTypeId: PropTypes.number,
  groupName: PropTypes.string,
  courseTypeName: PropTypes.string,
  groupState: PropTypes.oneOf(['in_session', 'in_extension']),
  groupBeginSessionAt: PropTypes.string,
  groupCloseSessionAt: PropTypes.string,
  groupExtendedUntil: PropTypes.string,
  facilitatorCount: PropTypes.number,
  isOps: PropTypes.bool,
  hasFacilitationPointAssignment: PropTypes.bool,
  facilitatedTaskIds: PropTypes.arrayOf(PropTypes.number),
  journalNotifications: PropTypes.objectOf(JournalNotificationPropType),
  discussionBoardNotifications: PropTypes.objectOf(DiscussionBoardNotificationPropType),
  insufficientParticipations: PropTypes.arrayOf(InsufficientParticipationPropType),
  extendedParticipants: PropTypes.arrayOf(ExtendedParticipantPropType),
  flaggedJournals: PropTypes.arrayOf(FlaggedJournalPropType),
});

const UserPropType = PropTypes.shape({
  id: PropTypes.number,
  login: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fullName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  languagePreference: PropTypes.oneOf(Object.values(LOCALES).concat(Object.values(LANGUAGE_PREFERENCES)).concat('')),
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  activation: PropTypes.oneOf(Object.values(ACTIVATION)),
  isFacilitator: PropTypes.bool,
  groupName: PropTypes.string,
  groupId: PropTypes.number,
  groupMembershipId: PropTypes.number,
  isIndigenousPlatformLead: PropTypes.bool,
  isIndigenousFacilitator: PropTypes.bool,
  isRegistrar: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isSuperFacilitator: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  hasFacilitatorAccess: PropTypes.bool,
  hasRegistrarAccess: PropTypes.bool,
  isHaRepresentative: PropTypes.bool,
  isSuperCurriculumEditor: PropTypes.bool,
  isPareReviewer: PropTypes.bool,
  isCurriculumReviewer: PropTypes.bool,
  isReportingUser: PropTypes.bool,
});
UserPropType.realUser = UserPropType;
export { UserPropType };

export const HealthAuthorityPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  shortName: PropTypes.string,
  parentId: PropTypes.number,
  welcomePackUrl: PropTypes.string,
  isAdvertised: PropTypes.bool,
  organizationId: PropTypes.number,
  requiresPayment: PropTypes.bool,
  isBlockedFromRegisteringForReleasedSeats: PropTypes.bool,
  organizationName: PropTypes.string,
});

export const GroupParticipationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  completion: PropTypes.string.isRequired,
  decoratedCompletion: PropTypes.string.isRequired,
  percentageDoneCourse: PropTypes.number,
  activation: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  subGroupId: PropTypes.number,
  isIndigenous: PropTypes.bool,
  canRestore: PropTypes.bool,
  certificateAvailable: PropTypes.bool,
  canResendPare: PropTypes.bool,
  organizationId: PropTypes.number,
  healthAuthorityId: PropTypes.number,
});

export const GroupPropType = PropTypes.shape({
  beginSessionAt: PropTypes.string,
  certificateAvailableAt: PropTypes.string,
  closeRegistrationAt: PropTypes.string,
  closeSessionAt: PropTypes.string,
  courseId: PropTypes.number,
  courseTypeId: PropTypes.number,
  createdAt: PropTypes.string,
  countableParticipants: PropTypes.number,
  expireReservationsAt: PropTypes.string,
  extendedUntil: PropTypes.string,
  id: PropTypes.number,
  internalNotes: PropTypes.string,
  maxParticipants: PropTypes.number,
  name: PropTypes.string,
  organizationId: PropTypes.number,
  reservationsState: PropTypes.string,
  reservedSeatsCount: PropTypes.number,
  registrationsCount: PropTypes.number,
  paymentPendingCount: PropTypes.number,
  totalAvailableSeatsCount: PropTypes.number,
  review: PropTypes.bool,
  sendParticipantListsAt: PropTypes.string,
  sendWelcomePacksAt: PropTypes.string,
  sittingTimeInHours: PropTypes.number,
  languagePreference: PropTypes.string,
  deleteable: PropTypes.bool,
  state: PropTypes.string,
  updatedAt: PropTypes.string,
  extendable: PropTypes.bool,
  hasExtension: PropTypes.string,
  totalParticipants: PropTypes.number,
  canManuallyAssignCourse: PropTypes.bool,
  isAvailableInFrench: PropTypes.bool,
  reports: PropTypes.shape({
    dashboard: PropTypes.bool,
    ptq: PropTypes.bool,
    quiz: PropTypes.bool,
    survey: PropTypes.bool,
  }),
  groupParticipations: PropTypes.arrayOf(GroupParticipationPropType),
});

export const LimitedGroupPropType = PropTypes.shape({
  beginSessionAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxParticipants: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  courseTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  review: PropTypes.bool,
  internalNotes: PropTypes.string,
});

export const OrganizationPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  appName: PropTypes.string,
  shortName: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  trainingVideoUrl: PropTypes.string,
  healthAuthorityLabel: PropTypes.string,
  permalink: PropTypes.string,
  approvedEmailDomains: PropTypes.arrayOf(PropTypes.string),
  showFormFields: PropTypes.arrayOf(PropTypes.string),
  employmentRegistrationNote: PropTypes.string,
  emailRegistrationNote: PropTypes.string,
  trainingDescription: PropTypes.string,
  privacyPolicy: PropTypes.string,
  termsOfService: PropTypes.string,
  allowParticipantGroupSwitching: PropTypes.bool,
  allowParticipantRegistrationCanceling: PropTypes.bool,
  healthAuthorities: PropTypes.objectOf(HealthAuthorityPropType),
  healthAuthorityIds: PropTypes.arrayOf(PropTypes.number),
});

export const CourseTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  organizationId: PropTypes.number,
  currentCourseId: PropTypes.number,
  closeRegistrationXDaysBeforeBegin: PropTypes.number,
  sendParticipantListsXDaysBeforeBegin: PropTypes.number,
  sendWelcomePacksXDaysBeforeBegin: PropTypes.number,
  durationInWeeks: PropTypes.number,
  priceInCents: PropTypes.number,
  prerequisites: PropTypes.arrayOf(PropTypes.number),
  expireReservationsXDaysBeforeBegin: PropTypes.number,
  isCore: PropTypes.bool,
  sittingTimeInHours: PropTypes.number,
  mainproM1Available: PropTypes.bool,
  mainproCAvailable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  nameFr: PropTypes.string,
  nameForCertificate: PropTypes.string,
  registrationNote: PropTypes.string,
  rcpscMocAvailable: PropTypes.bool,
  rcpscRegularAvailable: PropTypes.bool,
  theme: PropTypes.string,
  showParticipantReflectionsTab: PropTypes.bool,
  showSelfCareTab: PropTypes.bool,
  showGlossaryTab: PropTypes.bool,
  showJournalsTab: PropTypes.bool,
  showDiscussionsTab: PropTypes.bool,
  showMessagesTab: PropTypes.bool,
  isAvailableInFrench: PropTypes.bool,
  isOps: PropTypes.bool,
  hideFromCurriculumTab: PropTypes.bool,
  hideFromReports: PropTypes.bool,
  courseIds: PropTypes.arrayOf(PropTypes.number),
});

export const CourseGroupPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  participants: PropTypes.number.isRequired,
});

export const CourseSectionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nameFr: PropTypes.string,
  courseId: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  taskIds: PropTypes.arrayOf(PropTypes.number),
});

export const CoursePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  courseTypeId: PropTypes.number.isRequired,
  completionMilestoneSectionId: PropTypes.number,
  master: PropTypes.bool,
  editable: PropTypes.bool,
  archived: PropTypes.bool,
  groups: PropTypes.arrayOf(CourseGroupPropType),
  sections: PropTypes.arrayOf(CourseSectionPropType),
  status: PropTypes.oneOf(Object.values(COURSE_STATUS)),
});

export const GroupMembershipPropType = PropTypes.shape({
  activeSectionId: PropTypes.number,
  ageGroupId: PropTypes.number,
  ancestryId: PropTypes.number,
  authorizerEmail: PropTypes.string,
  authorizerName: PropTypes.string,
  authorizerPhone: PropTypes.string,
  completion: PropTypes.string,
  createdAt: PropTypes.string,
  createdBy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  department: PropTypes.string,
  educationLevelId: PropTypes.number,
  employeeNumber: PropTypes.string,
  endedAt: PropTypes.string,
  firstTaskCompletedAt: PropTypes.string,
  fullName: PropTypes.string,
  genderId: PropTypes.number,
  groupId: PropTypes.number,
  hasExtension: PropTypes.bool,
  healthAuthorityId: PropTypes.number,
  id: PropTypes.number,
  insufficientParticipationMarkedAt: PropTypes.string,
  internalNotes: PropTypes.string,
  isBulkImported: PropTypes.bool,
  isCurriculumReviewer: PropTypes.bool,
  isFacilitator: PropTypes.bool,
  isManuallyCreated: PropTypes.bool,
  isWastedSeat: PropTypes.bool,
  jobCategoryId: PropTypes.number,
  jobCategoryOther: PropTypes.string,
  lastSeenAt: PropTypes.string,
  latestCompletedSectionId: PropTypes.number,
  login: PropTypes.string,
  organizationId: PropTypes.number,
  percentageDoneCourse: PropTypes.number,
  physicianCreditType: PropTypes.string,
  postalCodeHealthAuthorityId: PropTypes.number,
  serviceOrganization: PropTypes.string,
  startedAt: PropTypes.string,
  subGroupId: PropTypes.number,
  tosAcceptedAt: PropTypes.string,
  trainingEndedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  userAgent: PropTypes.string,
  userId: PropTypes.number,
  voucherId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  isIndigenous: PropTypes.bool,
});

export const FacilitationSubgroupPropType = PropTypes.shape({
  createdAt: PropTypes.string,
  id: PropTypes.number,
  legacyFacilitationId: PropTypes.number,
  maxParticipants: PropTypes.number,
  name: PropTypes.string,
  parentGroupId: PropTypes.number,
  updatedAt: PropTypes.string,
});

export const TaskType = PropTypes.oneOf(Object.values(TASK_TYPES));

export const TaskPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  nameFr: PropTypes.string,
  description: PropTypes.string,
  summary: PropTypes.string,
  sectionId: PropTypes.number,
  type: TaskType.isRequired,
  position: PropTypes.number,
});

export const UserTaskPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  taskId: PropTypes.number.isRequired,
  groupMembershipId: PropTypes.number.isRequired,
  firstViewedAt: PropTypes.string,
  lastViewedAt: PropTypes.string,
  completedAt: PropTypes.string,
  activeTimeBeforeCompletion: PropTypes.number,
  activeTimeAfterCompletion: PropTypes.number,
  name: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  section: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
  }),
});

export const DiscussionBoardPropType = PropTypes.shape({
  id: PropTypes.number,
  courseTypes: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.string,
  durationInSeconds: PropTypes.number,
  finishedAt: PropTypes.string,
  relativePathToCsv: PropTypes.string,
  reportId: PropTypes.string,
  reportType: PropTypes.string,
  requestedByUserId: PropTypes.number,
  requestedByUserName: PropTypes.string,
  state: PropTypes.string,
  updatedAt: PropTypes.string,
  filters: PropTypes.shape({
    courseTypeIds: PropTypes.arrayOf(PropTypes.string),
    discussionBoards: PropTypes.arrayOf(PropTypes.string),
    endDate: PropTypes.string,
    facilitatorIds: PropTypes.arrayOf(PropTypes.string),
    reportSubType: PropTypes.string,
    startDate: PropTypes.string,
    totalUnfacilitatedPosts: PropTypes.number,
  }),
});

export const TrainingPaymentPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  subtotalAmountInCents: PropTypes.number,
  taxAmountInCents: PropTypes.number,
  grandTotalInCents: PropTypes.number,
  state: PropTypes.string.isRequired,
  triggerId: PropTypes.number,
  triggerType: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  notes: PropTypes.string,
});

export const VoucherPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  healthAuthorityId: PropTypes.number,
  courseTypeId: PropTypes.number,
  code: PropTypes.string,
  email: PropTypes.string,
  state: PropTypes.string,
  createdBy: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  markTrainingPaymentAs: PropTypes.string,
  notes: PropTypes.string,
  organizationTypeId: PropTypes.number,
  regionId: PropTypes.number,
  requireManager: PropTypes.bool.isRequired,
});

export const NotificationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  taskType: PropTypes.string,
  taskName: PropTypes.string,
  taskId: PropTypes.number,
  subGroupId: PropTypes.number,
  triggerGroupMembershipId: PropTypes.number,
  body: PropTypes.string,
  excerpt: PropTypes.string,
  createdAt: PropTypes.string,
});

export const StandardOptionsPropType = PropTypes.arrayOf(PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
}));

export const GroupedOptionsPropType = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  options: StandardOptionsPropType.isRequired,
}));

export const EmailConfirmationPropType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  from: PropTypes.string,
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  subject: PropTypes.string,
  to: PropTypes.string.isRequired,
  userId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  groupMembershipId: PropTypes.number,
});

export const MediaResourcePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  filename: PropTypes.string,
  path: PropTypes.string,
  type: PropTypes.oneOf(['audio', 'file', 'image', 'link', 'video']).isRequired,
});

export const LocalePropType = PropTypes.oneOf(Object.values(LOCALES));

export const QuestionPropType = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // for new questions
  ]),
  task_id: PropTypes.number,
  body: PropTypes.string,
  position: PropTypes.number,
  explanation: PropTypes.string,
  answer_required: PropTypes.bool,
  body_fr: PropTypes.string,
  explanation_fr: PropTypes.string,
});

export const ProactiveTemplatePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  internalName: PropTypes.string.isRequired,
  body: PropTypes.string,
  taskId: PropTypes.number.isRequired,
  taskName: PropTypes.string.isRequired,
  taskSummary: PropTypes.string,
  courseId: PropTypes.number.isRequired,
});

export const GlossaryEntryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired,
  term: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
  isFrench: PropTypes.bool.isRequired,
});

export const PhrasebookEntryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isFrench: PropTypes.bool.isRequired,
});

export const FindAndReplaceDataPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  highlighted: PropTypes.string.isRequired,
  replaced: PropTypes.string.isRequired,
  updated: PropTypes.bool.isRequired,
});

export const SelfCareArticlePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  courseId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  nameEn: PropTypes.string.isRequired,
  nameFr: PropTypes.string,
  descriptionEn: PropTypes.string.isRequired,
  descriptionFr: PropTypes.string,
  summaryEn: PropTypes.string,
  summaryFr: PropTypes.string,
  key: PropTypes.string.isRequired,
  version: PropTypes.number,
  position: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const BulkImportFormDataPropType = PropTypes.shape({
  ancestry: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  gender: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  jobCategory: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  educationLevel: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  ageGroup: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
});

export const SectionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  tasks: PropTypes.objectOf(TaskPropType).isRequired,
});

export const CommentPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  comment: PropTypes.string,
  createdAt: PropTypes.string,
  commentableId: PropTypes.number.isRequired,
  subGroupId: PropTypes.number.isRequired,
  groupMembershipId: PropTypes.number.isRequired,
  actualUserId: PropTypes.number,
  taskId: PropTypes.number.isRequired,
  taskType: PropTypes.string,
});

// https://react-select.com/advanced#custom-filter-logic
export const ReactSelectFilterOptionsPropType = PropTypes.shape({
  stringify: PropTypes.func,
  ignoreAccents: PropTypes.bool,
  ignoreCase: PropTypes.bool,
  trim: PropTypes.bool,
  matchFrom: PropTypes.string,
});

const AGE_LABELS = ['new', 'aging', 'old'];
const AgeLabelPropType = PropTypes.oneOf(AGE_LABELS);

export const JournalDataPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  primaryJournalNotificationCount: PropTypes.number.isRequired,
  secondaryJournalNotificationCount: PropTypes.number.isRequired,
  primaryAgeLabel: AgeLabelPropType,
  secondaryAgeLabel: AgeLabelPropType,
});

export const FacilitatorNotePropType = PropTypes.shape({
  id: PropTypes.number,
  subGroupId: PropTypes.number,
  taskId: PropTypes.number,
  quicknotes: PropTypes.string,
  notes: PropTypes.string,
});
