import React from 'react';
import BaseSkeleton from 'components/shared/Skeleton';

function Skeleton() {
  return (
    <>
      <div className="card border mb-3">
        <div className="card-header d-flex align-items-center justify-content-between">
          <BaseSkeleton classes="title mb-0 width-quarter" />
          <BaseSkeleton classes="text width-100 ms-auto" />
        </div>
        <div className="d-flex">
          <div className="py-3 px-4">
            <BaseSkeleton classes="icon large" />
          </div>
          <div className="w-100 py-3 px-2">
            <BaseSkeleton classes="d-block text strong width-half mb-1" />
            <BaseSkeleton classes="d-block text width-quarter" />

            <BaseSkeleton classes="d-block text width-full mt-3" />
            <BaseSkeleton classes="d-block text width-half mt-1" />
          </div>
        </div>
      </div>

      <div className="card border mb-3">
        <div className="card-header d-flex align-items-center justify-content-between">
          <BaseSkeleton classes="title mb-0 width-quarter" />
          <BaseSkeleton classes="text width-100 ms-auto" />
        </div>
        <div className="d-flex">
          <div className="py-3 px-4">
            <BaseSkeleton classes="icon large" />
          </div>
          <div className="w-100 py-3 px-2">
            <BaseSkeleton classes="d-block text strong width-half mb-1" />
            <BaseSkeleton classes="d-block text width-quarter" />

            <BaseSkeleton classes="d-block text width-full mt-3" />
            <BaseSkeleton classes="d-block text width-half mt-1" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Skeleton;
