import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Confirmation from 'components/shared/Modal/Confirmation';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD, UPDATE_COMMENT_FLAG } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import ParticipantInfoPopover from 'components/group_memberships/ParticipantInfoPopover';
import SanyasIcon from 'components/shared/SanyasIcon';
import MultilineText from 'components/shared/MultilineText';
import NotificationDot from '../NotificationDot';
import CommentFlag from './CommentFlag';
import CommentCardDropdown from './CommentCardDropdown';
import EditCommentCard from './EditCommentCard';
import Reply from './Reply';
import './styles.scss';

function CommentCard({ commentId, isReply, isJournalComment, answerId }) {
  const dispatch = useDispatch();
  const params = useParams();
  const currentUser = useSelector((state) => state.currentUser);
  const [showReplyBox, setShowReplyBox] = useState(false);
  const [showRemoveFlag, setShowRemoveFlag] = useState(false);
  const [editable, setIsEditable] = useState(false);
  const { assignedFacilitatorId } = useSelector((state) => state.facilitatorDashboard);
  const comment = useSelector((state) => state.facilitatorDashboard.commentsById[commentId]);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const saveFlag = useCallback(() => {
    setShowRemoveFlag(false);
    dispatch(UPDATE_COMMENT_FLAG.request({ id: commentId, flag: null }));
  }, [commentId, dispatch]);

  const markComment = useCallback(() => {
    if (comment.notificationCleared) {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ id: comment.notificationId, subGroupId: comment.subGroupId }, { cardType: CARD_TYPES.COMMENT_CARD, priority: comment.notificationPriority, gmId: comment.groupMembershipId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: comment.notificationId, subGroupId: comment.subGroupId }, { cardType: CARD_TYPES.COMMENT_CARD, priority: comment.notificationPriority, gmId: comment.groupMembershipId }));
    }
    document.activeElement.blur();
  }, [comment, dispatch]);

  if (!comment) return null;

  return (
    <div className={`mb-2 ${isReply ? 'col-md-10 justify-content-end' : 'w-100'}`}>
      {editable
        ? (
          <div className="col-md-12 mx-auto d-flex flex-column align-items-end">
            <EditCommentCard commentId={comment.id} isReply setIsEditable={setIsEditable} comment={comment} discussionTaskId={params.taskId} subGroupId={params.subGroupId} />
          </div>
        ) : (
          <>
            <div className={`card shadow-sm rounded pt-3 px-4 ${comment.isFacilitatorComment ? 'card-special' : 'bg-white'}`}>
              <div className="d-flex justify-content-between position-relative">
                {(!comment.notificationCleared && !comment.isFacilitatorComment) && (
                  <div className="position-absolute top-50 start-0 translate-middle" style={{ paddingRight: '1.375rem' }}>
                    <NotificationDot age={comment.notificationPriority} primary={comment.taskType === 'SubGroupDiscussionTask'} style={{ marginBottom: 2 }} />
                  </div>
                )}

                <div className="d-flex align-items-baseline fw-semibold">
                  {comment.isFacilitatorComment && (
                    <span className="text-accent-purple">
                      <SanyasIcon name="hummingbird" className="me-1" />
                      {comment.author}
                    </span>
                  )}
                  {!comment.isFacilitatorComment && (
                    <>
                      {!isJournalComment && <ParticipantInfoPopover participantId={comment.groupMembershipId} displayName={comment.author} />}
                      {isJournalComment && <SanyasIcon name="profileInfo" className="me-1 text-accent-land" />}
                      <a
                        href={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: comment.groupMembershipId })}
                        className="btn-plain"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {isJournalComment ? comment.fullName : comment.author}
                      </a>
                    </>
                  )}
                </div>

                {!comment.isFacilitatorComment && (
                  <div>
                    {isAssignedFacilitationPoint && (
                      <label className="btn-plain align-self-center read-label btn-primary me-2" htmlFor={comment?.id}>
                        <input
                          onChange={() => markComment()}
                          checked={comment.notificationCleared}
                          id={comment?.id}
                          name={comment?.author}
                          type="checkbox"
                          className="form-check-input me-2"
                        />
                        {comment.notificationCleared ? 'Read' : 'Unread'}
                      </label>
                    )}

                    {comment.flag && (
                      <CommentFlag
                        flag={comment.flag}
                        as={Button}
                        disabled={!isAssignedFacilitationPoint}
                        onClick={() => { setShowRemoveFlag(true); document.activeElement.blur(); }}
                        variant="link"
                      />
                    )}
                  </div>
                )}
              </div>

              <MultilineText text={comment.comment} />

              <div className="d-flex justify-content-end mb-1">
                {!isReply && (
                  <Button disabled={!isAssignedFacilitationPoint} onClick={() => setShowReplyBox(!showReplyBox)} variant="plain" className="me-1 px-1">
                    <SanyasIcon name="chat" className="me-1" />
                    Reply
                  </Button>
                )}
                <CommentCardDropdown isJournalComment={isJournalComment} disabled={!isAssignedFacilitationPoint} commentId={comment.id} setIsEditable={setIsEditable} />
              </div>
            </div>
            <div className="col-10 offset-2 mt-1">
              {showReplyBox && <Reply setShowReplyBox={setShowReplyBox} answerId={answerId} comment={comment} isJournal={isJournalComment} />}
            </div>
          </>
        )}

      <Confirmation
        title="Remove flag?"
        open={showRemoveFlag}
        close={() => setShowRemoveFlag(false)}
        text="Are you sure you want to remove the flag?"
        confirmText="Remove"
        confirmAction={saveFlag}
        cancelText={null}
      />
    </div>
  );
}

CommentCard.defaultProps = {
  isReply: false,
  isJournalComment: undefined,
  answerId: undefined,
};

CommentCard.propTypes = {
  commentId: PropTypes.number.isRequired,
  isReply: PropTypes.bool,
  isJournalComment: PropTypes.bool,
  answerId: PropTypes.number,
};

export default CommentCard;
