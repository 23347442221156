import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH } from 'lib/routerPaths';
import { FacilitationSubgroupPropType } from 'lib/propTypes';
import Badge from 'components/shared/Badge/Badge';

function SubGroupDetail({ subGroup, discussionTaskId, count }) {
  const { id } = useWorkspace();
  const commentsPath = buildRoutePath(FACILITATOR_DISCUSSION_BOARD_COMMENTS_PATH, { id: subGroup.parentGroupId, subGroupId: subGroup.id, taskId: discussionTaskId });

  const text = (
    <>
      {subGroup.name}
      <Badge title={count} pill className="ms-1" />
    </>
  );

  // If not already in the Facilitator workspace, use a full reload to reset the workspace
  return (
    <div className="mt-2">
      {id === 'facilitator' ? (
        <Link to={commentsPath} className="btn-plain">{text}</Link>
      ) : (
        <a href={commentsPath} className="btn-plain">{text}</a>
      )}
    </div>
  );
}

SubGroupDetail.propTypes = {
  subGroup: FacilitationSubgroupPropType.isRequired,
  discussionTaskId: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

export default SubGroupDetail;
