import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Overlay, Popover } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import { LOAD_PARTICIPANT } from 'store/facilitatorDashboard/actions';
import ParticipantInfo from './ParticipantInfo';

function ParticipantInfoPopover({ participantId, displayName }) {
  const participant = useSelector((state) => state.facilitatorDashboard.participants.byId[participantId]);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show && !participant?.id) {
      dispatch(LOAD_PARTICIPANT.request(participantId));
    }
  }, [participantId, show, participant?.id, dispatch]);

  return (
    <>
      <Button variant={null} className="participant-info" ref={target} onClick={() => setShow(!show)}>
        <SanyasIcon name="profileInfo" />
      </Button>

      <Overlay
        target={target.current}
        placement="bottom-start"
        show={show}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id={`participant-info-${participant?.id}`} className="participant-info-popover">
          <Popover.Body className="p-2">
            <ParticipantInfo participantId={participantId} displayName={displayName} popover={setShow} />
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

ParticipantInfoPopover.defaultProps = {
  displayName: null,
};

ParticipantInfoPopover.propTypes = {
  participantId: PropTypes.number.isRequired,
  displayName: PropTypes.string,
};

export default ParticipantInfoPopover;
