import React from 'react';
import PropTypes from 'prop-types';

function Badge({ title, variant, pill, reverse, alternate, className }) {
  return (
    <span
      className={`badge badge-${variant} ${reverse ? 'badge-reverse' : ''} ${alternate ? 'badge-alt' : ''} ${pill ? 'rounded-pill' : ''} ${className || ''}`}
    >
      { title }
    </span>
  );
}

Badge.defaultProps = {
  variant: 'green',
  pill: false,
  reverse: false,
  alternate: false,
  className: null,
};

Badge.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  variant: PropTypes.oneOf(['green', 'red', 'blue', 'grey', 'orange', 'db1', 'db2', 'db3', 'db4', 'db5', 'db6', 'j1', 'j2', 'j3', 'j4', 'j5', 'j6']),
  pill: PropTypes.bool,
  reverse: PropTypes.bool,
  alternate: PropTypes.bool,
  className: PropTypes.string,
};

export default Badge;
