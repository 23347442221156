import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { CARD_TYPES } from 'store/facilitatorDashboard/reducer';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH, FACILITATOR_JOURNAL_ANSWERS_PATH } from 'lib/routerPaths';
import { MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD } from 'store/facilitatorDashboard/actions';
import ParticipantInfoPopover from 'components/group_memberships/ParticipantInfoPopover';
import SanyasIcon from 'components/shared/SanyasIcon';
import { AnswerPropType } from 'lib/propTypes';
import MultilineText from 'components/shared/MultilineText';
import NotificationDot from '../NotificationDot';
import './styles.scss';

function JournalPreviewCard({ answer }) {
  const { subGroupId, taskId, id } = useParams();
  const dispatch = useDispatch();
  const [toggleBox, setToggleBox] = useState(answer.notificationCleared);
  const { assignedFacilitatorId } = useSelector((state) => state.facilitatorDashboard);
  const currentUser = useSelector((state) => state.currentUser);
  const isAssignedFacilitationPoint = currentUser?.id === assignedFacilitatorId;

  const markComment = useCallback((isChecked) => {
    setToggleBox(isChecked);
    if (isChecked) {
      dispatch(MARK_NOTIFICATION_AS_READ.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.PREVIEW_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    } else {
      dispatch(MARK_NOTIFICATION_AS_UNREAD.request({ id: answer.notificationId, subGroupId }, { cardType: CARD_TYPES.PREVIEW_CARD, priority: answer.notificationPriority, gmId: answer.participationId }));
    }
    document.activeElement.blur();
  }, [answer, dispatch, subGroupId]);

  return (
    <div className="ms-5 mb-2 col-md-11">
      <div className="mb-3 pt-3 px-4">
        <div className="d-flex justify-content-between position-relative">
          {!answer.notificationCleared && (
            <div className="position-absolute top-50 start-0 translate-middle" style={{ paddingRight: '1.375rem' }}>
              <NotificationDot age={answer.notificationPriority} />
            </div>
          )}

          <div className="d-flex align-items-baseline">
            <ParticipantInfoPopover participantId={answer.participationId} />
            <a
              href={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: answer.participationId })}
              className="btn-plain"
              target="_blank"
              rel="noreferrer"
            >
              {answer.fullName}
            </a>
          </div>

          <div>
            <label className="btn-plain align-self-center read-label btn-primary me-2" htmlFor={answer.answerId}>
              <input
                onChange={() => markComment(!answer.notificationCleared)}
                checked={toggleBox}
                disabled={!isAssignedFacilitationPoint}
                id={answer.answerId}
                name={answer.answerId}
                type="checkbox"
                className="form-check-input me-2"
              />
              {answer.notificationCleared ? 'Read' : 'Unread'}
            </label>
          </div>

        </div>

        <MultilineText text={answer.answer} />

        <div className="d-flex justify-content-end mb-1">

          <Link to={buildRoutePath(FACILITATOR_JOURNAL_ANSWERS_PATH, { id, subGroupId, taskId, groupMembershipId: answer.participationId })}>
            <SanyasIcon name="chat" className="me-1 align-center" />
            Reply
          </Link>
        </div>
      </div>
    </div>
  );
}

JournalPreviewCard.defaultProps = {
};

JournalPreviewCard.propTypes = {
  answer: AnswerPropType.isRequired,
};

export default JournalPreviewCard;
